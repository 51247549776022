import { IUnit, pageInfo } from "../../Common/Interfaces";
import { deleteUnitService, getUnitService, paginationUnitService, saveUnitService } from "../../services/MastersServices/UnitService";
import {  setAllUnitSlice, setUnitSlice } from "../../store/Slices/UnitSlice";
import { CRUD_MESSAGES } from "../../Common/Enums/SharedEnums";
import { notifyError, notifyTopRight } from "../../jsx/components/PluginsMenu/Toastr/Toastr";
import _ from 'lodash';

const defaultPage = {Page:1,PerPage:10}

export const  fetchUnitsThunk=()=>
{
    return async function (dispatch:any,getstate:any)
    { 
            try
            {
                const result = await getUnitService();
                dispatch(setAllUnitSlice(result.data))
            }
            catch(err)
            {
                console.log('err',err)
            }
    }
}
 export const saveUnitThunk=(payload:IUnit,pageInfo:pageInfo=defaultPage)=>{
    return async function (dispatch:any, getstate:any)
    {
        try{
                const result = await saveUnitService(payload);
                if(result.data.status===200){
                  dispatch(paginationUnitThunk(pageInfo))
                  notifyTopRight(_.isEmpty(payload.Id) ? CRUD_MESSAGES.SAVE_MSG : CRUD_MESSAGES.EDIT_MSG )
                }
                else{
                  notifyError(result.data.msg);
                }
        }
        catch(ex)
        {
            console.error('Error saveUnit',ex)
        }
    }
 }

 
export const deleteUnitThunk = (payload:IUnit,pageInfo:pageInfo=defaultPage) => {
    return async function deleteMasterUnitThunk(dispatch:any, getstate:any) {
      try 
      {
          const result = await deleteUnitService(payload)
          if(result.data.status===200)
            {
                dispatch(paginationUnitThunk(pageInfo))
                notifyTopRight(CRUD_MESSAGES.DELETE_MSG)
            }
            else
            {
                notifyError(result.data.msg);
            } 
      } 
      catch (ex) 
      {
        console.error('Error deleting unit', ex);
      }
    };
  };


  export const paginationUnitThunk = (payload:any) =>
  {
     return async function (dispatch:any,getstate:any)
     {
         try
         {
             const result = await paginationUnitService(payload)
             dispatch(setUnitSlice(result.data))
         }
         catch(ex)
         {
             console.log('Error pageUnitLoad',ex)
         }
     }
  }
