import { AxiosRequestConfig } from "axios";
import { ICategory } from "../../Common/Interfaces";
import axiosServiceInstance from "../AxiosInstanceService";
import { baseUrl, configHeaders } from "../../Common/Config/Config";

export const getCategoryService = () => {
  let config: AxiosRequestConfig = configHeaders;
  return axiosServiceInstance.get<ICategory[]>(
    `${baseUrl}/api/masters/categories`,
    config
  );
};

export const saveCategoryService = (payload: ICategory) => {
  let config: AxiosRequestConfig = configHeaders;
  return axiosServiceInstance.post(
    `${baseUrl}/api/masters/category`,
    payload,
    config
  );
};

export const deleteCategoryService = (payload: ICategory) => {
  let config: AxiosRequestConfig = configHeaders;
  let obj = { Id: payload };
  return axiosServiceInstance.post(
    `${baseUrl}/api/masters/removecategory`,
    obj,
    config
  );
};

export const paginatioCategoryService = (payload: ICategory) => {
  let config: AxiosRequestConfig = configHeaders;
  return axiosServiceInstance.post(
    `${baseUrl}/api/masters/pagecategories`,
    payload,
    config
  );
};
