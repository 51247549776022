import { useAppDispatch, useAppSelector } from "../../../../store/store";
import { Field, Form, Formik, FormikValues, useFormik } from "formik";
import {
  IPurchaseReport,
  ISalesReport,
} from "../../../../Common/Interfaces/IReport";
import { useNavigate } from "react-router-dom";
import { getOpeningBalanceSaleThunk } from "../../../../Thunk/ReportsThunk/SalesReportThunk";
import { Button, Modal } from "react-bootstrap";
import { FC, useEffect, useState } from "react";
import { fetchPartiesThunk } from "../../../../Thunk/MasterThunk/PartyThunk";
import { getToPartyVoucherThunk } from "../../../../Thunk/VouchersThunk/PaymentVoucherThunk";
import { TIME_PERIOD } from "../../../../Common/Enums/SharedEnums";
import { getPurchaseReportThunk } from "../../../../Thunk/ReportsThunk/PurchaseReportThunk";

interface ISaleModalProps {
  purchaseshow: boolean;
  handlePurchaseShow: () => void;
  handlePurchaseClose: () => void;
}
const PurchaseReportModal: FC<ISaleModalProps> = (props) => {
  const [TimePeriod, setTimePeriod] = useState("");
  const { purchaseshow, handlePurchaseShow, handlePurchaseClose } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleGenerateReport = async (values: IPurchaseReport) => {
    const res = await dispatch(getPurchaseReportThunk(values));
    if (res) handlePurchaseClose();
    navigate("/purchasereport");
  };

  const handleTimePeriodChange = (e: any, formik: FormikValues) => {
    setTimePeriod(e.target.value);
    function formatDate(date: Date) {
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      return `${year}-${month}-${day}`;
    }
    const today = new Date();
    if (e.target.value === "Todays") {
      const todaysDay = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      );
      formik.setFieldValue("FromDate", formatDate(todaysDay));
      formik.setFieldValue("ToDate", formatDate(todaysDay));
    } else if (e.target.value === "Current month") {
      const firstDayOfMonth = new Date(
        today.getFullYear(),
        today.getMonth(),
        1
      );
      const lastDayOfMonth = new Date(
        today.getFullYear(),
        today.getMonth() + 1,
        0
      );
      formik.setFieldValue("FromDate", formatDate(firstDayOfMonth));
      formik.setFieldValue("ToDate", formatDate(lastDayOfMonth));
    } else if (e.target.value === "Last month") {
      const firstDayOfLastMonth = new Date(
        today.getFullYear(),
        today.getMonth() - 1,
        today.getDate()
      );
      const lastDayOfLastMonth = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      );
      formik.setFieldValue("FromDate", formatDate(firstDayOfLastMonth));
      formik.setFieldValue("ToDate", formatDate(lastDayOfLastMonth));
    } else if (e.target.value === "Last 3 months") {
      const firstDayOfLast3Months = new Date(
        today.getFullYear(),
        today.getMonth() - 3,
        today.getDate()
      );
      const lastDayOfLastMonth = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      );
      formik.setFieldValue("FromDate", formatDate(firstDayOfLast3Months));
      formik.setFieldValue("ToDate", formatDate(lastDayOfLastMonth));
    } else if (e.target.value === "Last 6 months") {
      const firstDayOfLast6Months = new Date(
        today.getFullYear(),
        today.getMonth() - 6,
        today.getDate()
      );
      const lastDayOfLastMonth = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      );
      formik.setFieldValue("FromDate", formatDate(firstDayOfLast6Months));
      formik.setFieldValue("ToDate", formatDate(lastDayOfLastMonth));
    }
  };

  const handleCustomerChange = (values: FormikValues) => {
    dispatch(getOpeningBalanceSaleThunk(values));
  };

  const initialValues = {
    FromDate: "",
    ToDate: "",
  };

  useEffect(() => {
    dispatch(fetchPartiesThunk());
    dispatch(getToPartyVoucherThunk());
  }, [dispatch]);
  return (
    <>
      {" "}
      <Formik
        initialValues={initialValues}
        onSubmit={(values, onSubmitForm) => {
          handleCustomerChange(values);
          handleGenerateReport(values);
        }}
      >
        {(formik) => (
          <Form>
            <Modal show={purchaseshow} onHide={handlePurchaseClose}>
              <Modal.Header closeButton>
                <Modal.Title>Purchase Report</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row my-4">
                  <div className="col-md-12">
                    <label htmlFor="">Time Period</label>
                    <Field
                      as="select"
                      className="form-control text-primary mt-2 mt-sm-2 mt-md-2 mt-lg-0 mr-3 text-center"
                      name="TimePeriod"
                      id=""
                      onChange={(e: any) => {
                        formik.handleChange(`TimePeriod`)(e);
                        handleTimePeriodChange(e, formik);
                      }}
                    >
                      <option value="" disabled selected>
                        Select Time Period
                      </option>
                      {Object.values(TIME_PERIOD).map((type, index) => (
                        <option key={index} value={type}>
                          {type}
                        </option>
                      ))}
                    </Field>
                  </div>
                </div>
                {TimePeriod === "Custom" && (
                  <div className="row my-4">
                    <div className="col-md-6">
                      <label htmlFor="FromDate">From Date</label>
                      <Field
                        type="date"
                        name="FromDate"
                        id=""
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="ToDate">To Date</label>
                      <Field
                        type="date"
                        name="ToDate"
                        id=""
                        className="form-control"
                      />
                    </div>
                  </div>
                )}
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={() => handleGenerateReport(formik.values)}
                >
                  Generate Report
                </button>
                <Button variant="secondary" onClick={handlePurchaseClose}>
                  Close
                </Button>
                {/* </Link> */}
              </Modal.Footer>
            </Modal>{" "}
          </Form>
        )}
      </Formik>
    </>
  );
};

export default PurchaseReportModal;
