import { baseUrl, configHeaders } from "../../Common/Config/Config";
import { ILedger, ISalesReport } from "../../Common/Interfaces/IReport";
import axiosServiceInstance from "../AxiosInstanceService";
import { AxiosRequestConfig } from "axios";

export const getSalesReportService = (payload: ISalesReport) => {
  let config: AxiosRequestConfig = configHeaders;
  return axiosServiceInstance.post(
    `${baseUrl}/api/transaction/sales`,
    payload,
    config
  );
};
export const getOpeningBalanceSaleService = (openingBalancePayload: any) => {
  let config: AxiosRequestConfig = configHeaders;
  return axiosServiceInstance.post<ISalesReport[]>(
    `${baseUrl}/api/account/getbalance`,
    openingBalancePayload,
    config
  );
};
