import { CRUD_MESSAGES, REPORT_MESSAGES } from "../../Common/Enums/SharedEnums";
import { ISalesReport, IStock } from "../../Common/Interfaces/IReport";
import {
  notifyError,
  notifyTopRight,
} from "../../jsx/components/PluginsMenu/Toastr/Toastr";
import _ from "lodash";
import { setSale, setStock } from "../../store/Slices/Reports/ReportsSlice";
import {
  getOpeningBalanceSaleService,
  getSalesReportService,
} from "../../services/ReportsServices/SalesReportServices";
import { getSalesService } from "../../services/TransactionServices/SalesService";

export const getSalesReportThunk = (payload: ISalesReport) => {
  return async function (dispatch: any, getstate: any) {
    try {
      const result = await getSalesReportService(payload);
      console.log(payload);
      if (result.data.status === 200) {
        dispatch(setSale(result.data));
        notifyTopRight(REPORT_MESSAGES.GENERATE_SALES);
        return true;
      } else {
        notifyError(result.data.message);
      }
    } catch (ex) {
      console.error("Error stock", ex);
    }
  };
};

export const getOpeningBalanceSaleThunk = (payload: any) => {
  const openingBalancePayload = {
    saleId: payload.saleID,
    OnDate: payload.fromdate,
  };
  return async function (dispatch: any, getstate: any) {
    try {
      const result = await getOpeningBalanceSaleService(openingBalancePayload);
      dispatch(setSale(result.data));
    } catch (err) {
      console.log("err", err);
    }
  };
};
