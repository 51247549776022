import { AxiosRequestConfig } from "axios";
import { ILogin } from "../../Common/Interfaces/Login/SuperRegister";
import axiosServiceInstance from "../AxiosInstanceService";
import { baseUrl, configHeaders } from "../../Common/Config/Config";
import { store } from "../../store/store";

export const superLoginService = (payload: ILogin) => {
  let config: AxiosRequestConfig = {
    headers: { "content-type": "multipart/form-data" }
  };
  return axiosServiceInstance.post(`${baseUrl}/api/super/superUserLogin`, payload, config)
}

export const getSuperLoginDataService = (id: string) => {
  const state = store.getState();
  const token = state.loginconfirm.data.idToken;
  let config: AxiosRequestConfig = {
    headers: {
        "content-type": "multipart/form-data",
        "Authorization": token
      }
};
  return axiosServiceInstance.get(`${baseUrl}/api/super/superlogindata/${id}`, config);
}

