export const MenuList = [
  {
    title: "Dashboard",
    classsChange: "mm-collapse",
    iconStyle: <i className="flaticon-381-networking" />,
    to: "dashboard",
  },
  {
    title: "Masters",
    classsChange: "mm-collapse",
    iconStyle: <i className="flaticon-381-settings-2" />,
    to: "",
    content: [
      {
        title: "Unit",
        to: "unit",
        content: [],
        iconStyle: "",
      },
      {
        title: "Category",
        to: "category",
        content: [],
        iconStyle: "",
      },
      {
        title: "Manufacturer",
        to: "manufacturer",
        content: [],
        iconStyle: "",
      },
      {
        title: "Firm",
        to: "firm",
        content: [],
        iconStyle: "",
      },
      {
        title: "Item",
        to: "item",
        content: [],
        iconStyle: "",
      },
      {
        title: "Party",
        to: "party",
        content: [],
        iconStyle: "",
      },
      {
        title: "Extra Charges",
        to: "extracharges",
        content: [],
        iconStyle: "",
      },
      {
        title: "Terms and Conditions",
        to: "tandc",
        content: [],
        iconStyle: "",
      },
      {
        title: "Ledger Master",
        to: "ledger",
        content: [],
        iconStyle: "",
      },
    ],
  },
  {
    title: "Sales",
    classsChange: "mm-collapse",
    iconStyle: <i className="flaticon-381-controls-3" />,
    to: "",
    content: [
      {
        title: "Quotation",
        to: "quotation",
        content: [],
        iconStyle: "",
      },
      {
        title: "Delivery Challan",
        to: "deliverychallan",
        content: [],
        iconStyle: "",
      },
      {
        title: "DC Return",
        to: "deliverychallanreturn",
        content: [],
        iconStyle: "",
      },
      {
        title: "Sales",
        to: "sales",
        content: [],
        iconStyle: "",
      },
      {
        title: "Sales Return",
        to: "salesreturn",
        content: [],
        iconStyle: "",
      },
    ],
  },
  {
    title: "Purchase",
    classsChange: "mm-collapse",
    iconStyle: <i className="flaticon-381-controls-3" />,
    to: "",
    content: [
      {
        title: "Purchase Inward",
        to: "purchase",
        content: [],
        iconStyle: "",
      },
      {
        title: "Purchase Challan",
        to: "purchasechallan",
        content: [],
        iconStyle: "",
      },
      {
        title: "PC Return",
        to: "purchasechallanreturn",
        content: [],
        iconStyle: "",
      },
      {
        title: "Purchase Order",
        to: "purchaseorder",
        content: [],
        iconStyle: "",
      },
      {
        title: "Purchase Return",
        to: "purchasereturn",
        content: [],
        iconStyle: "",
      },
    ],
  },
  {
    title: "Accounts",
    classsChange: "mm-collapse",
    iconStyle: <i className="fa fa-book" />,
    to: "",
    content: [
      {
        title: "Payment",
        to: "payment",
        content: [],
        iconStyle: "",
      },
      {
        title: "Receipt",
        to: "receipt",
        content: [],
        iconStyle: "",
      },
      {
        title: "Contra Voucher",
        to: "contravoucher",
        content: [],
        iconStyle: "",
      },
    ],
  },
  {
    title: "Reports",
    classsChange: "mm-collapse",
    iconStyle: <i className="fa fa-file" />,
    to: "/reports",
    content: [],
  },
  // {
  //   title: "User Management",
  //   classsChange: "mm-collapse",
  //   iconStyle: <i className="flaticon-381-controls-3" />,
  //   to: "usermanagement",

  {
    title: "User Management",
    classsChange: "mm-collapse",
    iconStyle: (
      <i
        className="fa fa-user
    "
      />
    ),
    to: "",
    content: [
      {
        title: "Masters",
        classsChange: "mm-collapse",
        // iconStyle: <i className="la fab la-windows" />,
        to: "",
        content: [
          {
            title: "Tab",
            to: "tab",
            content: [],
            iconStyle: "",
          },
          {
            title: "Activity",
            to: "activity",
            content: [],
            iconStyle: "",
          },
          {
            title: "Role Master",
            to: "role",
            content: [],
            iconStyle: "",
          },
          {
            title: "Icon Master",
            to: "iconmaster",
            content: [],
            iconStyle: "",
          },
        ],
      },

      {
        title: "Profile",
        classsChange: "",
        iconStyle: <i className="la la-user" />,
        to: "profile",
        content: [],
      },

      {
        title: "Office",
        classsChange: "mm-collapse",
        iconStyle: <i className="la la-building" />,
        to: "",
        content: [
          {
            title: "Type",
            to: "type",
            content: [],
            iconStyle: "",
          },
          {
            title: "Branch",
            to: "branch",
            content: [],
            iconStyle: "",
          },
          {
            title: "Employee",
            to: "employee",
            content: [],
            iconStyle: "",
          },
        ],
      },
    ],
  },
  //Apps
  // {
  //     title: 'Apps',
  //     classsChange: 'mm-collapse',
  //     iconStyle:  <i className="flaticon-381-television" />,
  //     content: [
  //         {
  //             title: 'Profile',
  //             to: 'app-profile'
  //         },

  //         {
  //             title: 'Post Details',
  //             to: 'post-details'
  //         },
  //         {
  //             title: 'Email',
  //             //to: './',
  //             hasMenu : true,
  //             content: [
  //                 {
  //                     title: 'Compose',
  //                     to: 'email-compose',
  //                 },
  //                 {
  //                     title: 'Index',
  //                     to: 'email-inbox',
  //                 },
  //                 {
  //                     title: 'Read',
  //                     to: 'email-read',
  //                 }
  //             ],
  //         },
  //         {
  //             title:'Calendar',
  //             to: 'app-calender'
  //         },
  //         {
  //             title: 'Shop',
  //             //to: './',
  //             hasMenu : true,
  //             content: [
  //                 {
  //                     title: 'Product Grid',
  //                     to: 'ecom-product-grid',
  //                 },
  //                 {
  //                     title: 'Product List',
  //                     to: 'ecom-product-list',
  //                 },
  //                 {
  //                     title: 'Product Details',
  //                     to: 'ecom-product-detail',
  //                 },
  //                 {
  //                     title: 'Order',
  //                     to: 'ecom-product-order',
  //                 },
  //                 {
  //                     title: 'Checkout',
  //                     to: 'ecom-checkout',
  //                 },
  //                 {
  //                     title: 'Invoice',
  //                     to: 'ecom-invoice',
  //                 },
  //                 {
  //                     title: 'Customers',
  //                     to: 'ecom-customers',
  //                 },
  //             ],
  //         },
  //     ],
  // },
  // //Charts
  // {
  //     title: 'Charts',
  //     classsChange: 'mm-collapse',
  //     iconStyle: <i className="flaticon-381-controls-3" />,
  //     content: [

  //         {
  //             title: 'RechartJs',
  //             to: 'chart-rechart',
  //         },
  //         {
  //             title: 'Chartjs',
  //             to: 'chart-chartjs',
  //         },
  //         {
  //             title: 'Sparkline',
  //             to: 'chart-sparkline',
  //         },
  //         {
  //             title: 'Apexchart',
  //             to: 'chart-apexchart',
  //         },
  //     ]
  // },
  // //Boosttrap
  // {
  //     title: 'Bootstrap',
  //     classsChange: 'mm-collapse',
  //     iconStyle: <i className="flaticon-381-internet" />,
  //     content: [
  //         {
  //             title: 'Accordion',
  //             to: 'ui-accordion',
  //         },
  //         {
  //             title: 'Alert',
  //             to: 'ui-alert',
  //         },
  //         {
  //             title: 'Badge',
  //             to: 'ui-badge',
  //         },
  //         {
  //             title: 'Button',
  //             to: 'ui-button',
  //         },
  //         {
  //             title: 'Modal',
  //             to: 'ui-modal',
  //         },
  //         {
  //             title: 'Button Group',
  //             to: 'ui-button-group',
  //         },
  //         {
  //             title: 'List Group',
  //             to: 'ui-list-group',
  //         },
  //         {
  //             title: 'Cards',
  //             to: 'ui-card',
  //         },
  //         {
  //             title: 'Carousel',
  //             to: 'ui-carousel',
  //         },
  //         {
  //             title: 'Dropdown',
  //             to: 'ui-dropdown',
  //         },
  //         {
  //             title: 'Popover',
  //             to: 'ui-popover',
  //         },
  //         {
  //             title: 'Progressbar',
  //             to: 'ui-progressbar',
  //         },
  //         {
  //             title: 'Tab',
  //             to: 'ui-tab',
  //         },
  //         {
  //             title: 'Typography',
  //             to: 'ui-typography',
  //         },
  //         {
  //             title: 'Pagination',
  //             to: 'ui-pagination',
  //         },
  //         {
  //             title: 'Grid',
  //             to: 'ui-grid',
  //         },
  //     ]
  // },
  // //plugins
  // {
  //     title:'Plugins',
  //     classsChange: 'mm-collapse',
  //     iconStyle : <i className="flaticon-381-heart" />,
  //     content : [
  //         {
  //             title:'Select 2',
  //             to: 'uc-select2',
  //         },
  //         // {
  //         //     title:'Noui Slider',
  //         //     to: 'uc-noui-slider',
  //         // },
  //         {
  //             title:'Sweet Alert',
  //             to: 'uc-sweetalert',
  //         },
  //         {
  //             title:'Toastr',
  //             to: 'uc-toastr',
  //         },
  //         {
  //             title:'Jqv Map',
  //             to: 'map-jqvmap',
  //         },
  //         {
  //             title:'Light Gallery',
  //             to: 'uc-lightgallery',
  //         },
  //     ]
  // },
  // //Widget
  // {
  //     title:'Widget',
  //     //classsChange: 'mm-collapse',
  //     iconStyle: <i className="flaticon-381-settings-2" />,
  //     to: 'widget-basic',
  // },
  // //Forms
  // {
  //     title:'Forms',
  //     classsChange: 'mm-collapse',
  //     iconStyle: <i className="flaticon-381-notepad" />,
  //     content : [
  //         {
  //             title:'Form Elements',
  //             to: 'form-element',
  //         },
  //         {
  //             title:'Wizard',
  //             to: 'form-wizard',
  //         },
  //         {
  //             title:'CkEditor',
  //             to: 'form-ckeditor',
  //         },
  //         {
  //             title:'Pickers',
  //             to: 'form-pickers',
  //         },
  //         {
  //             title:'Form Validate',
  //             to: 'form-validation',
  //         },

  //     ]
  // },
  // //Table
  // {
  //     title:'Table',
  //     classsChange: 'mm-collapse',
  //     iconStyle: <i className="flaticon-381-network" />,
  //     content : [
  //         {
  //             title:'Table Filtering',
  //             to: 'table-filtering',
  //         },
  //         {
  //             title:'Table Sorting',
  //             to: 'table-sorting',
  //         },
  //         {
  //             title:'Bootstrap',
  //             to: 'table-bootstrap-basic',
  //         },

  //     ]
  // },
  // //Pages
  // {
  //     title:'Pages',
  //     classsChange: 'mm-collapse',
  //     iconStyle: <i className="flaticon-381-layer-1" />,
  //     content : [
  //         {
  //             title:'Error',
  //             hasMenu : true,
  //             content : [
  //                 {
  //                     title: 'Error 400',
  //                     to : 'page-error-400',
  //                 },
  //                 {
  //                     title: 'Error 403',
  //                     to : 'page-error-403',
  //                 },
  //                 {
  //                     title: 'Error 404',
  //                     to : 'page-error-404',
  //                 },
  //                 {
  //                     title: 'Error 500',
  //                     to : 'page-error-500',
  //                 },
  //                 {
  //                     title: 'Error 503',
  //                     to : 'page-error-503',
  //                 },
  //             ],
  //         },
  //         {
  //             title:'Lock Screen',
  //             to: 'page-lock-screen',
  //         },

  //     ]
  // },
];
