import React from "react";

const DeliverChallanReturnGrid = () => {
  return (
    <div>
      <table className="table dataTable table-striped">
        <thead>
          <th>Challan No</th>
          <th>Date</th>
          <th>Total Quantity</th>
        </thead>
        <tbody></tbody>
      </table>
    </div>
  );
};

export default DeliverChallanReturnGrid;
