import swal from "sweetalert";
const defaultDeleteButtons = ["Cancel", "Ok"];

const SweetConfirm = async (
  title: string,
  text: string,
  buttons: string[] = defaultDeleteButtons,
  data: string = "This"
) => {
  if (data.length > 20) {
    data = data.substring(0, 20) + "...";
  }

  if (data !== "This") {
    text = text.replace("This", `<b>${data}</b>`);
  }
  // @ts-ignore
  const confirm = await swal({
    title: title,
    content: {
      element: "div",
      attributes: {
        innerHTML: text,
      },
    },
    icon: "warning",
    buttons: [buttons[0], buttons[1]],
    dangerMode: true,
  });

  if (confirm) {
    return true;
  } else {
    return false;
  }
};

export default SweetConfirm;
