import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import PostsReducer from "./reducers/PostsReducer";
import thunk from "redux-thunk";
import { AuthReducer } from "./reducers/AuthReducer";
import todoReducers from "./reducers/Reducers";
import { Tuple, configureStore } from "@reduxjs/toolkit";
//import { reducer as reduxFormReducer } from 'redux-form';
import unitReducers from "./Slices/UnitSlice";
import CategoryReducer from "./Slices/CategorySlice";
import FirmReducer from "./Slices/FirmSlice";
import ItemReducer from "./Slices/ItemSlice";
import PartyReducer from "./Slices/PartySlice";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import ManufacturerSlice from "./Slices/ManufacturerSlice";
import ExtrachargesSlice from "./Slices/ExtachargesSlice";
import TermsAndConditionsSlice from "./Slices/TermsAndConditionsSlice";
import SalesSlice from "./Slices/Transactions/SalesSlice";
import TabSlice from "./Slices/User Management/UserMaster/TabSlice";
import ActivitySlice from "./Slices/User Management/UserMaster/ActivitySlice";
import RoleSlice from "./Slices/User Management/UserMaster/RoleSlice";
import IconSlice from "./Slices/User Management/UserMaster/IconSlice";
import TypeSlice from "./Slices/User Management/UserOffice/TypeSlice";
import ProfileSlice from "./Slices/User Management/UserProfile/ProfileSlice";
import ProfileActivitySlice from "./Slices/User Management/UserProfile/ProfileControllerSlice/ProfileActivitySlice";
import ProfileTabSlice from "./Slices/User Management/UserProfile/ProfileControllerSlice/ProfileTabSlice";
import ProfileRoleSlice from "./Slices/User Management/UserProfile/ProfileControllerSlice/ProfileRoleSlice";
import BranchSlice from "./Slices/User Management/UserOffice/BranchSlice";
import CountrySlice from "./Slices/User Management/UserOffice/CountrySlice";
import StateSlice from "./Slices/User Management/UserOffice/StateSlice";
import CitySlice from "./Slices/User Management/UserOffice/CitySlice";
import EmployeeSlice from "./Slices/User Management/UserOffice/EmployeeSlice";
import SuperRegisterSlice from "./Slices/Login/SuperRegisterSlice";
import SuperLoginSlice from "./Slices/Login/SuperLoginSlice";
import EmpLoginSlice from "./Slices/Login/EmpLoginSlice";
import LoginConfirmedSlice from "./Slices/Login/LoginConfirmedSlice";
import CommonLoginDataSlice from "./Slices/Login/CommonLoginDataSlice";
import QuotationsSlice from "./Slices/Transactions/QuotationsSlice";
import SalesReturnSlice from "./Slices/Transactions/SalesReturnSlice";
import PurchaseSlice from "./Slices/Transactions/PurchaseSlice";
import PurchaseReturnSlice from "./Slices/Transactions/PurchaseReturnSlice";
import DeliveryChallanSlice from "./Slices/Transactions/DeliveryChallanSlice";
import ReportsSlice from "./Slices/Reports/ReportsSlice";
import VouchersSlice from "./Slices/Vouchers/VouchersSlice";
import DashboardSlice from "./Slices/Dashboard/DashboardSlice";
import PurchaseOrderSlice from "./Slices/Transactions/PurchaseOrderSlice";
import PurchaseChallanSlice from "./Slices/Transactions/PurchaseChallanSlice";
import LedgerMasterSlice from "./Slices/LedgerMasterSlice";
const middlewares = applyMiddleware(thunk);

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
  //Dashboard
  dashboard: DashboardSlice,

  //masters
  posts: PostsReducer,
  auth: AuthReducer,
  todoReducers,
  unit: unitReducers,
  category: CategoryReducer,
  firm: FirmReducer,
  item: ItemReducer,
  party: PartyReducer,
  manufacturer: ManufacturerSlice,
  extracharges: ExtrachargesSlice,
  terms: TermsAndConditionsSlice,
ledgermaster:LedgerMasterSlice,
  //transactions
  sales: SalesSlice,
  quotations: QuotationsSlice,
  salesreturn: SalesReturnSlice,
  purchase: PurchaseSlice,
  purchasereturn: PurchaseReturnSlice,
  purchaseorder: PurchaseOrderSlice,
  deliverychallan: DeliveryChallanSlice,
purchasechallan:PurchaseChallanSlice,
  //reports
  reports: ReportsSlice,

  // vouchers
  vouchers: VouchersSlice,

  //form: reduxFormReducer,
  // User Management
  tab: TabSlice,
  icon: IconSlice,
  activity: ActivitySlice,
  role: RoleSlice,
  type: TypeSlice,
  profile: ProfileSlice,

  //user Management profile control
  profileActivity: ProfileActivitySlice,
  profileTab: ProfileTabSlice,
  profileRole: ProfileRoleSlice,
  country: CountrySlice,
  state: StateSlice,
  city: CitySlice,
  branch: BranchSlice,
  employee: EmployeeSlice,

  //Login
  superregister: SuperRegisterSlice,
  superlogin: SuperLoginSlice,
  emplogin: EmpLoginSlice,
  loginconfirm: LoginConfirmedSlice,
  commonlogin: CommonLoginDataSlice,
});

//const store = createStore(rootReducers);

// export const store = createStore(reducers,  composeEnhancers(middleware));

export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: middlewares,
      },
      serializableCheck: false,
    }),
});

export const useAppDispatch: () => typeof store.dispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<
  ReturnType<typeof store.getState>
> = useSelector;
