import { IManufacturer, pageInfo } from "../../Common/Interfaces";
import { deleteManufacturerService, getManufacturersService, paginationManufacturerService, saveManufacturersService } from "../../services/MastersServices/ManufacturefService";
import { setAllManufacturersSlice, setManufacturersSlice } from "../../store/Slices/ManufacturerSlice";
import { CRUD_MESSAGES } from "../../Common/Enums/SharedEnums";
import { notifyError, notifyTopRight } from "../../jsx/components/PluginsMenu/Toastr/Toastr";
import _ from 'lodash';

const defaultPage = {Page:1,PerPage:10}

export const  fetchManufacturersThunk=()=>
{ 
    return async function (dispatch:any,getstate:any)
    {
            try
            {
                const result = await getManufacturersService();
                dispatch(setAllManufacturersSlice(result.data))

            }
            catch(err)
            {
                console.log('err',err)
            }
    }
}
 export const saveManufacturerThunk=(payload:IManufacturer,pageInfo:pageInfo=defaultPage)=>{
    return async function (dispatch:any, getstate:any)
    {
        try{
                const result = await saveManufacturersService(payload);
                if(result.data.status===200){
                    dispatch(paginationManufacturerThunk(pageInfo))
                    notifyTopRight(_.isEmpty(payload.Id) ? CRUD_MESSAGES.SAVE_MSG : CRUD_MESSAGES.EDIT_MSG )
                }
                else{
                    notifyError(result.data.msg);
                } 
                
        }
        catch(ex)
        {
            console.error('Error saveManufacturer',ex)
        }
    }
 }

 export const deleteManufacturerThunk = (payload:IManufacturer,pageInfo:pageInfo=defaultPage)=>
 {
    return async function (dispatch:any ,getstate:any)
    {
        try
        {
            const result = await deleteManufacturerService(payload);
            if(result.data.status===200)
            {
                dispatch(paginationManufacturerThunk(pageInfo))  
                notifyTopRight(CRUD_MESSAGES.DELETE_MSG)
            }
            else
            {
                notifyError(result.data.msg);
            } 
        }
        catch(ex)
        {
            console.warn('Error deleteManufacturer',ex)
        }
    }
 }

 export const paginationManufacturerThunk = (payload:pageInfo) =>
 {
    return async function (dispatch:any,getstate:any)
    {
        try
        {
            const result = await paginationManufacturerService(payload)
            dispatch(setManufacturersSlice(result.data))
        }
        catch(ex)
        {
            console.log('Error pagePartyLoad',ex)
        }
    }
 }