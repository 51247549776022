import React from "react";
import { Link } from "react-router-dom";

interface FormTitleProps {
  pageHeading: string;
}

const FormTitle: React.FC<FormTitleProps> = (props) => {
  const { pageHeading } = props;
  return <h4 className="text-primary ">{pageHeading}</h4>;
};

export default FormTitle;
