import { IBill, IQuotation } from "../../Common/Interfaces/ITransaction";
import {
  notifyError,
  notifyTopRight,
} from "../../jsx/components/PluginsMenu/Toastr/Toastr";
import _ from "lodash";
import {
  deleteSaleService,
  getSaleService,
  getSalesByCustIdService,
  getSalesService,
  saveSalesService,
} from "../../services/TransactionServices/SalesService";
import {
  addSales,
  setSalePrint,
  setSalesByCustIdObj,
} from "../../store/Slices/Transactions/SalesSlice";
import { CRUD_MESSAGES } from "../../Common/Enums/SharedEnums";
import {
  deleteQuotationThunk,
  fetchQuotationsByCustomerThunk,
  saveQuotationsThunk,
} from "./QuotationThunk";

export const fetchSalesThunk = () => {
  return async function (dispatch: any, getstate: any) {
    try {
      const result = await getSalesService();
      dispatch(addSales(result.data));
    } catch (err) {
      console.log("err", err);
    }
  };
};
export const fetchSaleThunk = (saleId: string) => {
  return async function (dispatch: any) {
    try {
      const result = await getSaleService(saleId);
      dispatch(setSalePrint(result.data));
    } catch (err) {
      console.log("err", err);
    }
  };
};

export const fetchSalesByCustIdThunk = (custId: string | undefined) => {
  return async function (dispatch: any) {
    try {
      const result = await getSalesByCustIdService(custId);
      dispatch(setSalesByCustIdObj(result.data));
    } catch (err) {
      console.log("err", err);
    }
  };
};


export const saveSalesThunk = (payload: IBill) => {
  return async function (dispatch: any, getstate: any) {
    try {
      console.log("payload ", payload);
      const result = await saveSalesService(payload);

      if (result.data.status === 200) {
        dispatch(fetchSalesThunk());
        notifyTopRight(
          _.isEmpty(payload.BillNo)
            ? CRUD_MESSAGES.SAVE_MSG
            : CRUD_MESSAGES.EDIT_MSG
        );
      } else {
        console.log(result);
        notifyError(result.data.msg);
      }
    } catch (ex) {
      console.error("Error saveCategory", ex);
    }
  };
};
export const saveSalesFromQuotation = (
  payload: IBill,
  quoteObj: IQuotation
) => {
  return async function (dispatch: any, getstate: any) {
    try {
      // const idForDelete = payload.Id;
      payload.Id = "";
      // Modify the copy of quoteObj
      const updatedQuoteObj = { ...quoteObj, IsInvoiced: "1" };
      const result = await saveSalesService(payload);
      if (result.data.status === 200) {
        // dispatch(deleteQuotationThunk(idForDelete));
        dispatch(saveQuotationsThunk(updatedQuoteObj));
        dispatch(fetchQuotationsByCustomerThunk(payload.CustId));
        notifyTopRight(
          _.isEmpty(payload.BillNo)
            ? CRUD_MESSAGES.SAVE_MSG
            : CRUD_MESSAGES.EDIT_MSG
        );
      } else {
        console.log(result);
        notifyError(result.data.msg);
      }
    } catch (ex) {
      console.error("Error saveCategory", ex);
    }
  };
};

export const deleteSaleThunk = (saleId: string | undefined) => {
  return async function (dispatch: any) {
    try {
      const result = await deleteSaleService(saleId);
      dispatch(fetchSalesThunk());
    } catch (err) {
      console.log("err", err);
    }
  };
};
