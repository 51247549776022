import { setLoginConfirmedSlice } from "../store/Slices/Login/LoginConfirmedSlice";

export function saveTokenInLocalStorage(tokenDetails: any, dispatch: any) {
  const newTokenDetails = {
    ...tokenDetails,
    expireDate: new Date(new Date().getTime() + tokenDetails.expiresIn * 1000),
  };
  localStorage.setItem("userDetails", JSON.stringify(newTokenDetails));
  dispatch(setLoginConfirmedSlice(newTokenDetails));
}

export function runLogoutTimer(dispatch: any, timer: number, navigate: any) {
  setTimeout(() => {
    logout(dispatch, navigate);
  }, timer);
}

export function checkAutoLogin(dispatch: any, navigate: any, pathname: any) {
  const tokenDetailsString = localStorage.getItem("userDetails");
  let tokenDetails: any = ""; // Initialize as any to avoid type inference issues
  if (!tokenDetailsString) {
    logout(dispatch, navigate, pathname);
    return;
  }
  tokenDetails = JSON.parse(tokenDetailsString);
  let expireDate = new Date(tokenDetails.expireDate);
  let todaysDate = new Date();

  if (todaysDate > expireDate) {
    logout(dispatch, navigate);
    return;
  }

  dispatch(setLoginConfirmedSlice(tokenDetails));
  const timer = expireDate.getTime() - todaysDate.getTime();
  runLogoutTimer(dispatch, timer, navigate);

  if (
    (tokenDetails.idToken && pathname === "/login") ||
    pathname === "/emplogin"
  ) {
    navigate("/dashboard");
  }
}

export function logout(dispatch: any, navigate: any, pathname = "") {
  console.log("here is pathname in logout", pathname);
  const userDetailsString = localStorage.getItem("userDetails");

  if (userDetailsString !== null) {
    const tokenDetails = JSON.parse(userDetailsString);
    tokenDetails["idToken"] = "";
    localStorage.removeItem("userDetails");
    localStorage.removeItem("userType");
    dispatch(setLoginConfirmedSlice(tokenDetails));
    navigate("/login");
  }
  if (pathname === "/emplogin") {
    navigate("/emplogin");
  } else {
    navigate("/login");
  }
}
