import { useEffect } from "react";
import {
  ICategory,
  IItem,
  IManufacturer,
  IUnit,
  pageInfo,
} from "../../../../Common/Interfaces";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import { saveItemThunk } from "../../../../Thunk/MasterThunk/ItemThunk";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { fetchUnitsThunk } from "../../../../Thunk/MasterThunk/UnitThunk";
import { fetchManufacturersThunk } from "../../../../Thunk/MasterThunk/ManufacturerThunk";
import { fetchCategoriesThunk } from "../../../../Thunk/MasterThunk/CategoryThunk";

interface IItemProp {
  item: IItem;
  pageInfo: pageInfo;
}

const ItemModal: React.FunctionComponent<IItemProp> = (props) => {
  const { item, pageInfo } = props;
  const dispatch = useAppDispatch();
  function handleSave(arg0: IItem, arg1: pageInfo): void {
    dispatch(saveItemThunk(arg0, arg1));
  }

  const units = useAppSelector((state: any) => state.unit.allData);
  const manufacturers = useAppSelector(
    (state: any) => state.manufacturer.allData
  );
  const categories = useAppSelector((state: any) => state.category.allData);

  useEffect(() => {
    dispatch(fetchUnitsThunk());
    dispatch(fetchManufacturersThunk());
    dispatch(fetchCategoriesThunk());
  }, [dispatch]);

  let initialValues: IItem = {
    Id: item.Id,
    UnitId: item.UnitId,
    UnitName: item.UnitName,
    ItemName: item.ItemName,
    Desc: item.Desc,
    Barcode: item.Barcode,
    OpeningQty: item.OpeningQty,
    CategoryId: item.CategoryId,
    CategoryName: item.CategoryName,
    ItemCode: item.ItemCode,
    TaxRate: item.TaxRate,
    HSNCode: item.HSNCode,
    ManufacturerId: item.ManufacturerId,
    ManufacturerName: item.ManufacturerName,
    Packing: item.Packing,
    Shelf: item.Shelf,
    MaxLevel: item.MaxLevel,
    RecorderLevel: item.RecorderLevel,
    LastPurchaseRate: item.LastPurchaseRate,
    MRP: item.MRP,
    Discount: item.Discount,
    CashRate: item.CashRate,
    CreditRate: item.CreditRate,
    WholesaleRate: item.WholesaleRate,
    Rate4: item.Rate4,
    Rate5: item.Rate5,
    Status: item.Status,
    CreatedBy: item.CreatedBy,
    UpdatedBy: item.UpdatedBy
  };

  console.log("here is initial values tax rate",initialValues.TaxRate)
  
  const validationSchema = Yup.object().shape({
    // HSNCode: Yup.number().required("HSN Code is required"),
    ItemName: Yup.string()
      .min(2, "At least 2 characters")
      .required("Name is required"),
    // CategoryId: Yup.string().required("Category is required"),
    // UnitId: Yup.string().required("Unit is required"),
    // TaxRate: Yup.string().required("Tax Rate is required"),
    // LastPurchaseRate: Yup.number().required("Purchase Rate is required"),
    // OpeningQty: Yup.number().required('Opening Quantity is required'),
    // RecorderLevel: Yup.string().required('Reorder is required'),
    // MRP: Yup.number().required("MRP is required"),
    // WholesaleRate: Yup.number().required("Sales Price is required"),
    // ManufacturerId: Yup.string().required("Manufacturer is required"),
    // Packing: Yup.string().required("Packing is required"),
  });

  const taxRates = [
    { label: "0%", value: "0" },
    { label: "5%", value: "5" },
    { label: "12%", value: "12" },
    { label: "18%", value: "18" },
    { label: "28%", value: "28" },
  ];

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={(values, onSubmitForm) => {
          const salesPrice = values.MRP - values.Discount;
          onSubmitForm.setFieldValue("SalesPrice", salesPrice);
          handleSave(values, pageInfo);
          onSubmitForm.resetForm();
        }}
      >
        {(formik) => (
          <Form>
            <div className="modal fade" id="itemModal">
              <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Add Item</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                    >
                      <span>×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="default-tab">
                      <ul className="nav nav-tabs" role="tablist">
                        <li className="nav-item">
                          <a
                            className="nav-link  active"
                            id="itemInfo-tab"
                            data-toggle="tab"
                            href="#itemInfo"
                            role="tab"
                            aria-controls="itemInfo"
                            aria-selected="false"
                          >
                            <i
                              className="la la-cart-plus mr-2"
                              aria-hidden="true"
                            ></i>{" "}
                            Item Info
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link "
                            id="inventoryInfo-tab"
                            data-toggle="tab"
                            href="#inventoryInfo"
                            role="tab"
                            aria-controls="inventoryInfo"
                            aria-selected="false"
                          >
                            <i className="la la-shopping-bag mr-2"></i>Inventory
                            Info
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="tab-content" id="itemTabContent">
                      <div
                        className="tab-pane fade show active"
                        id="itemInfo"
                        role="tabpanel"
                        aria-labelledby="itemInfo-tab"
                      >
                        <div className="basic-form">
                          <div className="form-row mt-2">
                            <div className="form-group col-md-8  order-md-1">
                              <label>
                                Item Name{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <Field
                                type="text"
                                id="ItemName"
                                className="form-control"
                                placeholder="ex.Bourbon"
                                name="ItemName"
                              />
                              <p className="text-danger">
                                <ErrorMessage name="ItemName" />
                              </p>
                            </div>
                            <div className="form-group col-md-8 order-md-3">
                              <label>Description </label>
                              <Field
                                as="textarea"
                                rows="1"
                                id="Desc"
                                className="form-control"
                                placeholder="ex. Lal ambari powder small packets"
                                name="Desc"
                              />
                              <p className="text-danger">
                                <ErrorMessage name="Desc" />
                              </p>
                            </div>
                            <div className="form-group col-md-4 mb-2 order-md-2">
                              <label htmlFor="itemCategory">
                                Category <span style={{ color: "red" }}>*</span>
                              </label>
                              <Field
                                as="select"
                                className="form-control"
                                id="CategoryId"
                                name="CategoryId"
                              >
                                <option value="" disabled selected hidden>
                                  Choose...
                                </option>
                                {categories
                                  ?.filter(
                                    (category: ICategory) => category.Status
                                  )
                                  .map(
                                    (
                                      category: IManufacturer,
                                      index: number
                                    ) => (
                                      <option
                                        key={category.Id}
                                        value={category.Id}
                                      >
                                        {category.Name}
                                      </option>
                                    )
                                  )}
                              </Field>
                              <p className="text-danger">
                                <ErrorMessage name="CategoryId" />
                              </p>
                            </div>
                            
                            <div className="form-group col-md-4 order-md-4">
                              <label htmlFor="UnitId">
                                Unit <span style={{ color: "red" }}>*</span>
                              </label>
                              <Field
                                as="select"
                                className="form-control"
                                id="UnitId"
                                name="UnitId"
                              >
                                <option value="" disabled selected hidden>
                                  Choose...
                                </option>
                                {units
                                  ?.filter((unit: IUnit) => unit.Status)
                                  .map((unit: IUnit, index: number) => (
                                    <option key={unit.Id} value={unit.Id}>
                                      {unit.Name}
                                    </option>
                                  ))}
                              </Field>
                              <p className="text-danger">
                                <ErrorMessage name="UnitId" />
                              </p>
                            </div>
                            <div className="form-group col-md-4 order-md-7">
                              <label htmlFor="TaxRate">
                                Tax Rate %{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <Field
                                as="select"
                                className="form-control"
                                id="TaxRate"
                                name="TaxRate"
                              >
                                <option value="" disabled hidden>
                                  Choose...
                                </option>
                                {taxRates.map((rate) => (
                                  <option key={rate.value} value={rate.value}>
                                    {rate.label}
                                  </option>
                                ))}
                              </Field>
                              <p className="text-danger">
                                <ErrorMessage name="TaxRate" />
                              </p>
                            </div>
                            <div className="form-group col-md-8 order-md-8">
                              <label htmlFor="ManufacturerId">
                                Manufacturer{" "}
                              </label>
                              <Field
                                as="select"
                                className="form-control"
                                id="ManufacturerId"
                                name="ManufacturerId"
                              >
                                <option value="" disabled selected hidden>
                                  Choose...
                                </option>
                                {manufacturers
                                  ?.filter(
                                    (manufacturer: IManufacturer) =>
                                      manufacturer.Status
                                  )
                                  .map(
                                    (
                                      manufacturer: IManufacturer,
                                      index: number
                                    ) => (
                                      <option
                                        key={manufacturer.Id}
                                        value={manufacturer.Id}
                                      >
                                        {manufacturer.Name}
                                      </option>
                                    )
                                  )}
                              </Field>
                              <p className="text-danger">
                                <ErrorMessage name="ManufacturerId" />
                              </p>
                            </div>
                            {/* <div className="form-row"> */}
                            <div className="form-group col-12 col-md-4 order-md-5">
                              <label>HSN Code </label>
                              <Field
                                name="HSNCode"
                                type="text"
                                id="HSNCode"
                                className="form-control"
                                placeholder="ex. 123456"
                              />
                              <p className="text-danger">
                                <ErrorMessage name="HSNCode" />
                              </p>
                            </div>

                            <div className="form-group col-12 col-md-4 order-md-6">
                              <label>Barcode</label>
                              <Field
                                name="Barcode"
                                type="text"
                                id="Barcode"
                                className="form-control"
                                placeholder="ex. ABC123"
                              />
                              <p className="text-danger">
                                <ErrorMessage name="Barcode" />
                              </p>
                            </div>

                            
                            {/* </div> */}
                            
                            <div className="form-group col-md-4 order-md-9">
                              <label>Packing</label>
                              <Field
                                type="text"
                                id="Packing"
                                className="form-control"
                                placeholder="ex. 250ML"
                                name="Packing"
                              />
                              <p className="text-danger">
                                <ErrorMessage name="Packing" />
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="inventoryInfo"
                        role="tabpanel"
                        aria-labelledby="inventoryInfo-tab"
                      >
                        <div className="basic-form">
                          <div className="form-row mt-3">
                            <div className="form-group  col-12 col-md-4">
                              <label>
                                Purchase Rate (₹){" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <Field
                                type="number"
                                id="LastPurchaseRate"
                                className="form-control"
                                name="LastPurchaseRate"
                                placeholder="ex. 0.00"
                              />
                              <p className="text-danger">
                                <ErrorMessage name="LastPurchaseRate" />
                              </p>
                            </div>

                            <div className="form-group col-md-4">
                              <label>
                                MRP (₹) <span style={{ color: "red" }}>*</span>
                              </label>
                              <Field
                                type="number"
                                id="MRP"
                                className="form-control"
                                name="MRP"
                                placeholder="ex. 0.00"
                              />
                              <p className="text-danger">
                                <ErrorMessage name="MRP" />
                              </p>
                            </div>

                            <div className="form-group col-md-4">
                              <label htmlFor="Desc">Discount</label>
                              <Field
                                type="number"
                                id="Discount"
                                className="form-control"
                                name="Discount"
                                placeholder="ex. 20"
                              />
                              <p className="text-danger">
                                <ErrorMessage name="Discount" />
                              </p>
                            </div>
                            {/* <div className="form-row"> */}
                            <div className="form-group col-12 col-md-4">
                              <label>
                                Sales Rate1 (₹){" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <Field
                                type="text"
                                id="WholesaleRate"
                                className="form-control"
                                name="WholesaleRate"
                                placeholder="ex. 0.00"
                              />
                              <p className="text-danger">
                                <ErrorMessage name="WholesaleRate" />
                              </p>
                            </div>
                            <div className="form-group  col-md-4">
                              <label>
                                Sales Rate2 (₹)
                              </label>
                              <Field
                                type="text"
                                id="Rate4"
                                className="form-control"
                                name="Rate4"
                                placeholder="ex. 0.00"
                              />
                              <p className="text-danger">
                                <ErrorMessage name="Rate4" />
                              </p>
                            </div>
                            <div className="form-group  col-md-4">
                              <label>
                                Sales Rate3 (₹)
                              </label>
                              <Field
                                type="text"
                                id="Rate5"
                                className="form-control"
                                name="Rate5"
                                placeholder="ex. 0.00"
                              />
                              <p className="text-danger">
                                <ErrorMessage name="Rate5" />
                              </p>
                            </div>
                            {/* </div> */}
                            <div className="form-group  col-md-6">
                              <hr />
                              <label>Opening Qty</label>
                              <Field
                                type="text"
                                id="OpeningQty"
                                className="form-control"
                                name="OpeningQty"
                                placeholder="ex. 0"
                              />
                              <p className="text-danger">
                                <ErrorMessage name="OpeningQty" />
                              </p>
                            </div>
                            <div className="form-group col-12 col-md-6">
                              <hr />
                              <label>Reorder Qty</label>
                              <Field
                                type="text"
                                id="RecorderLevel"
                                className="form-control"
                                name="RecorderLevel"
                                placeholder="ex. 1"
                              />
                              <p className="text-danger">
                                <ErrorMessage name="RecorderLevel" />
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="submit"
                      disabled={!formik.isValid || !formik.dirty}
                      className={`btn btn-primary ${!formik.isValid || !formik.dirty ? "CursorNA" : ""
                        }`}
                    >
                      Save changes
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger light"
                      data-dismiss="modal"
                      onClick={() => formik.resetForm()}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ItemModal;
