import React, { useEffect, useRef, useState } from "react";
import "./PurchaseReport.css";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import { IBill } from "../../../../Common/Interfaces/ITransaction";
import { fetchSalesThunk } from "../../../../Thunk/TransactionThunk/SalesThunk";
import { useReactToPrint } from "react-to-print";
import SalesReportModal from "./PurchaseReportModal";
import PurchaseReportModal from "./PurchaseReportModal";

const PurchaseReport = () => {
  const [purchaseshow, setPurchaseshow] = useState(false);
  const handlePurchaseClose = () => setPurchaseshow(false);
  const handlePurchaseShow = () => setPurchaseshow(true);

  const sales = useAppSelector((state) => state.reports.saleData);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (sales) {
      dispatch(fetchSalesThunk());
    }
  }, []);

  const openingBalance = useAppSelector(
    (state) => state.reports.openingBalance
  );

  let DebitTotal = 0,
    CreditTotal = 0;
  sales &&
    sales?.map((ledger: any) => {
      DebitTotal += Number(ledger.Debit);
      CreditTotal += Number(ledger.Credit);
    });

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    pageStyle: `@media print {
        @page {
          margin-top: 20px;
        }
      }`,
    documentTitle: "Sales Report",
    content: () => componentRef.current,
  });

  return (
    <>
      <div className="PrintTable">
        <PurchaseReportModal
          purchaseshow={purchaseshow}
          handlePurchaseShow={handlePurchaseShow}
          handlePurchaseClose={handlePurchaseClose}
        />

        <div className="d-flex align-items-center">
          {" "}
          <button className=" btn btn-primary mb-4 mr-2" onClick={handlePrint}>
            Print
          </button>{" "}
          <button className=" btn btn-primary mb-4 mr-2">CSV</button>
          <button className="btn btn-success mb-4 mr-2">Excel</button>{" "}
          <button
            className="btn btn-primary mb-4 mr-2"
            onClick={handlePurchaseShow}
          >
            <i className="fa fa-filter"></i> Filter
          </button>{" "}
        </div>

        <div className="card " ref={componentRef}>
          <div className="card-body">
            <h3 className="text-center mb-0">Soulsoft Pvt. Ltd.</h3>
            <p className="text-center mb-2">
              S10 B , AHMEDNAGAR, MAHARASHTRA, 422605
            </p>
            <h4 className="text-center bg-primary text-white p-1">
              Purchase Report
            </h4>
            <div className="d-flex align-items-center justify-content-between p-2 my-2  bg-light ">
              <h5 className="mb-0 ">
                <strong> Ledger Name:</strong> {sales?.StockName}
              </h5>
              <h5 className="mb-0 ">
                <strong> Date:</strong> {sales?.FromDate} To {sales?.ToDate}
              </h5>
            </div>

            <table className="PrintTable">
              <thead className="bg-primary">
                <th>Sr. No.</th>
                <th>Date</th>
                <th>Invoice No</th>
                <th>Party Name</th>
                <th>Payment Type</th>
                <th>Disc (%)</th>
                <th>Total Amount (₹)</th>
              </thead>
              <tbody>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    {" "}
                    <strong>Opening Balance (₹)</strong>
                  </td>
                  <td>{openingBalance}</td>
                  <td></td>
                  <td></td>
                </tr>{" "}
                {sales?.map((sale: IBill, index: number) => (
                  <tr key={sale.Id}>
                    <td>{index + 1}</td>
                    <td>{sale.BillDate}</td>
                    <td>{sale.BillNo}</td>
                    <td>{sale.PartyName}</td>
                    <td>{sale.PaymentType}</td>
                    <td>{sale.TotDiscPer}%</td>
                    <td>{sale.NetBillAmt}</td>
                  </tr>
                ))}
                <tr>
                  <td colSpan={5}>
                    <strong>Grand Total</strong>
                  </td>
                  <td>
                    <strong>{CreditTotal}</strong>
                  </td>
                  <td>
                    <strong>{DebitTotal}</strong>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td colSpan={5}></td>
                  <td>
                    <strong>Closing Balance (₹)</strong>
                  </td>
                  <td colSpan={2}></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default PurchaseReport;
