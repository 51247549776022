import { AxiosRequestConfig } from "axios";
import { IManufacturer, pageInfo } from "../../Common/Interfaces";
import axiosServiceInstance from "../AxiosInstanceService";
import { baseUrl, configHeaders } from "../../Common/Config/Config";

export const getManufacturersService = () => {
  let config: AxiosRequestConfig = configHeaders;
  return axiosServiceInstance.get<IManufacturer[]>(
    `${baseUrl}/api/masters/manufacturers`,
    config
  );
};

export const saveManufacturersService = (payload: IManufacturer) => {
  let config: AxiosRequestConfig = configHeaders;
  return axiosServiceInstance.post(
    `${baseUrl}/api/masters/manufacturer`,
    payload,
    config
  );
};

export const deleteManufacturerService = (payload: IManufacturer) => {
  let config: AxiosRequestConfig = configHeaders;
  const obj = { Id: payload };
  return axiosServiceInstance.post(
    `${baseUrl}/api/masters/removebrand`,
    obj,
    config
  );
};

export const paginationManufacturerService = (payload: pageInfo) => {
  let config: AxiosRequestConfig = configHeaders;
  return axiosServiceInstance.post(
    `${baseUrl}/api/masters/pagemanufacturers`,
    payload,
    config
  );
};
