import { AxiosRequestConfig } from "axios";
import axiosServiceInstance from "../AxiosInstanceService";
import { IBill } from "../../Common/Interfaces/ITransaction";
import { baseUrl, configHeaders } from "../../Common/Config/Config";
import { ISalesReport } from "../../Common/Interfaces/IReport";

export const getSalesService = () => {
  let config: AxiosRequestConfig = configHeaders;
  return axiosServiceInstance.get<IBill[]>(
    `${baseUrl}/api/transaction/sales`,
    config
  );
};

export const getSaleService = (saleId: string) => {
  let config: AxiosRequestConfig = configHeaders;
  return axiosServiceInstance.get<IBill[]>(
    `${baseUrl}/api/transaction/sale/${saleId}`,
    config
  );
};

export const getSalesByCustIdService = (custId: string | undefined) => {
  let config: AxiosRequestConfig = configHeaders;
  return axiosServiceInstance.get<IBill[]>(
    `${baseUrl}/api/transaction/salesbycustid/${custId}`,
    config
  );
};

export const saveSalesService = (payload: IBill) => {
  let config: AxiosRequestConfig = configHeaders;
  return axiosServiceInstance.post(
    `${baseUrl}/api/transaction/sale`,
    payload,
    config
  );
};

export const deleteSaleService = (saleId: string | undefined) => {
  let config: AxiosRequestConfig = configHeaders;
  let obj = { Id: saleId };
  return axiosServiceInstance.post(
    ` ${baseUrl}/api/transaction/sale/remove`,
    obj,
    config
  );
};
