import states from "./States";
import { IParty, pageInfo } from "../../../../Common/Interfaces";
import { useAppDispatch } from "../../../../store/store";
import { savePartyThunk } from "../../../../Thunk/MasterThunk/PartyThunk";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

interface IPartyProp {
  party: IParty;
  pageInfo: pageInfo;
}

const PartyModal: React.FunctionComponent<IPartyProp> = (props) => {
  const { party, pageInfo } = props;
  const dispatch = useAppDispatch();
  function handleSave(arg0: IParty, arg1: pageInfo): void {
    dispatch(savePartyThunk(arg0, arg1));
  }

  const initialValues: IParty = {
    Id: party.Id,
    PartyNo: party.PartyNo,
    PartyName: party.PartyName,
    PartyType: party.PartyType,
    Address: party.Address,
    State: party.State,
    Email: party.Email,
    Mobile: party.Mobile,
    PAN: party.PAN,
    ADHAR: party.ADHAR,
    Desc: party.Desc,
    LedgerId: party.LedgerId,
    OpeningBalance: party.OpeningBalance,
    CreditLimit: party.CreditLimit,
    CreditPeriod: party.CreditLimit,
    GSTIN: party.GSTIN,
    Status: party.Status,
    CreatedBy: party.CreatedBy,
    UpdatedBy: party.UpdatedBy,
    Route:party.Route,
    FixedRate: party.FixedRate
  };

  const validationSchema = Yup.object().shape({
    PartyName: Yup.string()
      .min(3, "At least 3 characters or more")
      .required("Party Name is required"),
    PartyType: Yup.string().required("Party Type is required"),
    Mobile: Yup.string().matches(
      /^(?:\+?[0-9]+)?\/?(?:\+?[0-9]+)?$/,
      "invalid number"
    ),
    // .required("Mobile is required"),
    Address: Yup.string().min(5, "At least 5 characters"),
    State: Yup.string().required("State is required"),
    Email: Yup.string().email("Invalid email address"),
    Desc: Yup.string().min(5, "Atleast 5 characters or more"),
    OpeningBalance: Yup.number(),
    CreditLimit: Yup.number(),
    CreditPeriod: Yup.number(),
  });

  const state = states.map((state) => ({
    value: state.value,
    label: state.label,
  }));
  const partyTypeSelect = [
    { label: "Supplier", value: "supplier" },
    { label: "Customer", value: "customer" },
  ];

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={(values, onSubmitForm) => {
          handleSave(values, pageInfo);
          onSubmitForm.resetForm();
        }}
      >
        {(formik) => (
          <Form>
            <div className="modal fade" id="partyModal">
              <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Add Party</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                    >
                      <span>×</span>
                    </button>
                  </div>
                  <div className="modal-body pt-2 pb-0">
                    <div className="row d-flex justify-content-between">
                      <div className="default-tab ml-3">
                        <ul
                          className="nav nav-tabs nav card-header-undefined"
                          role="tablist"
                        >
                          <li className="nav-item">
                            <a
                              className="nav-link  active"
                              id="partyInfo-tab"
                              data-toggle="tab"
                              href="#partyInfo"
                              role="tab"
                              aria-controls="partyInfo"
                              aria-selected="false"
                            >
                              <i className="la la-user mr-2"></i>Party
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link  "
                              id="accountInfo-tab"
                              data-toggle="tab"
                              href="#accountInfo"
                              role="tab"
                              aria-controls="accountInfo"
                              aria-selected="false"
                            >
                              <i className="la la-money mr-2"></i>Account
                            </a>
                          </li>
                        </ul>
                      </div>
                      <Field
                        as="select"
                        className="form-control col-11  col-lg-3 ml-3 mt-2 mt-sm-2 mt-md-2 mt-lg-0 mr-3 text-center"
                        name="PartyType"
                        id="PartyType"
                        style={{ color: "red" }}
                      >
                        <option value="" disabled selected hidden>
                          Party Type *
                        </option>
                        {partyTypeSelect.map((option, index) => (
                          <option key={index} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </Field>
                    </div>
                    <div className="tab-content" id="partyTabContent">
                      <div
                        className="tab-pane fade show active"
                        id="partyInfo"
                        role="tabpanel"
                        aria-labelledby="partyInfo-tab"
                      >
                        <div className="basic-form">
                          <div className="form-row mt-4">
                            <div className="form-group col-12 col-md-9 ">
                              <label>
                                Party Name{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <Field
                                type="text"
                                name="PartyName"
                                id="PartyName"
                                className="form-control"
                                placeholder="ex. ABCDE"
                              />
                              <p className="text-danger">
                                <ErrorMessage name="PartyName" />
                              </p>
                            </div>

                            <div className="form-group col-12 col-md-3">
                              <label>Mobile No </label>
                              <Field
                                name="Mobile"
                                type="text"
                                id="Mobile"
                                className="form-control"
                                placeholder="ex. 1234567890"
                              />
                              <p className="text-danger">
                                <ErrorMessage name="Mobile" />
                              </p>
                            </div>
                            </div>
                          <div className="form-row">
                          <div className="form-group col-12 col-md-6">
                              <label>Address</label>
                              <Field
                                as="textarea"
                                id="Address"
                                name="Address"
                                className="form-control"
                                placeholder="ex. "
                                rows="2"
                              />
                              <p className="text-danger">
                                <ErrorMessage name="Address" />
                              </p>
                            </div>
                            <div className="form-group col-12 col-md-6">
                              <label>Route</label>
                              <Field
                                as="textarea"
                                id="Route"
                                name="Route"
                                className="form-control"
                                placeholder="ex. "
                                rows="2"
                              />
                              <p className="text-danger">
                                <ErrorMessage name="Route" />
                              </p>
                            </div>
                            </div>

                          <div className="form-row">
                            <div className="form-group col-12 col-md-4">
                              <label>GSTIN</label>
                              <Field
                                name="GSTIN"
                                type="text"
                                id="GSTIN"
                                className="form-control"
                                placeholder="ex. ABC123"
                              />
                              <p className="text-danger">
                                <ErrorMessage name="GSTIN" />
                              </p>
                            </div>
                            <div className="form-group col-12 col-md-4">
                              <label>PAN</label>
                              <Field
                                name="PAN"
                                type="text"
                                id="PAN"
                                className="form-control"
                                placeholder="ex. ABCDE1234A"
                              />
                            </div>
                            <div className="form-group col-12 col-md-4">
                              <label>State </label>
                              <Field
                                as="select"
                                id="State"
                                name="State"
                                className="form-control"
                              >
                                <option defaultValue={"Maharashtra"}>
                                  Maharashtra
                                </option>
                                {state.map((option, index) => (
                                  <option key={index} value={option.value}>
                                    {option.label}
                                  </option>
                                ))}
                              </Field>
                              <p className="text-danger">
                                <ErrorMessage name="State" />
                              </p>
                            </div>
                            
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="addressInfo"
                        role="tabpanel"
                        aria-labelledby="addressInfo-tab"
                      >
                        <div className="basic-form">
                          <div className="form-row mt-4"></div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="accountInfo"
                        role="tabpanel"
                        aria-labelledby="accountInfo-tab"
                      >
                        <div className="basic-form">
                          <div className="form-row mt-4">
                            <div className="form-group col-12 col-md-6">
                              <label>Opening Balance (₹)</label>
                              <Field
                                name="OpeningBalance"
                                type="number"
                                id="OpeningBalance"
                                className="form-control"
                                placeholder="ex. 15"
                              />
                              <p className="text-danger">
                                <ErrorMessage name="OpeningBalance" />
                              </p>
                            </div>
                            <div className="form-group col-12 col-md-6">
                              <label>Fixed Discount (%)</label>
                              <Field
                                name="FixedRate"
                                type="number"
                                id="FixedRate"
                                className="form-control"
                                placeholder="ex. 15"
                              />
                              <p className="text-danger">
                                <ErrorMessage name="OpeningBalance" />
                              </p>
                            </div>
                            <div className="form-group col-12 col-md-6">
                              <label>Credit Limit (₹)</label>
                              <Field
                                name="CreditLimit"
                                type="number"
                                id="CreditLimit"
                                className="form-control"
                                placeholder="ex. 3"
                              />
                              <p className="text-danger">
                                <ErrorMessage name="CreditLimit" />
                              </p>
                            </div>
                            <div className="form-group col-12 col-md-6">
                              <label>Credit Period (Days)</label>
                              <Field
                                name="CreditPeriod"
                                type="number"
                                id="CreditPeriod"
                                className="form-control"
                                placeholder="ex. 1"
                              />
                              <p className="text-danger">
                                <ErrorMessage name="CreditPeriod" />
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                  <button
                      type="submit"
                      className={`btn btn-primary ${
                        !formik.isValid || !formik.dirty ? "CursorNA" : ""
                      }`}
                      disabled={!formik.isValid || !formik.dirty}
                    >
                      Save changes
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger light"
                      data-dismiss="modal"
                      onClick={() => formik.resetForm()}
                    >
                      Close
                    </button>
                   
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default PartyModal;
