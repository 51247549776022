import { createSlice } from "@reduxjs/toolkit";

enum STATUSES {
  IDLE = "idle",
  ERROR = "error",
  LOADING = "loading",
}
const initialState: any = {
  data: [],
  status: STATUSES.IDLE,
};
const DeliveryChallanSlice = createSlice({
  name: "deliverychallan",
  initialState,
  reducers: {
    addDeliveryChallan(state, action) {
      state.data = action.payload.data;
    },
    removeDeliveryChallan(state, action) {
      return state.filter((item: any) => item.id !== action.payload);
    },
    setDeliveryChallanPrint(state, action) {
      state.deliverychallan = action.payload.data;
    },
    setDeliveryQuotations(state, action) {
      state.deliveryquotations = action.payload.data;
    },
  },
});

export const { addDeliveryChallan, removeDeliveryChallan,setDeliveryQuotations, setDeliveryChallanPrint } = DeliveryChallanSlice.actions;
export default DeliveryChallanSlice.reducer;
