import { AxiosRequestConfig } from "axios";
import { baseUrl, configHeaders } from "../../Common/Config/Config";
import axiosServiceInstance from "../AxiosInstanceService";
import { IPaymentVoucher } from "../../Common/Interfaces/IVoucher";

export const savePaymentVoucher = (payload: IPaymentVoucher) => {
  let config: AxiosRequestConfig = configHeaders;
  return axiosServiceInstance.post(
    `${baseUrl}/api/account/voucher`,
    payload,
    config
  );
};
export const getFromPartyVoucher = () => {
  let config: AxiosRequestConfig = configHeaders;
  return axiosServiceInstance.get<IPaymentVoucher[]>(
    `${baseUrl}/api/masters/frompartylist`,
    config
  );
};
export const getToPartyVoucher = () => {
  let config: AxiosRequestConfig = configHeaders;
  return axiosServiceInstance.get<IPaymentVoucher[]>(
    `${baseUrl}/api/masters/topartylist`,
    config
  );
};
