import { createSlice } from "@reduxjs/toolkit";
import { IBill } from "../../../Common/Interfaces/ITransaction";

enum STATUSES {
  IDLE = "idle",
  ERROR = "error",
  LOADING = "loading",
}
const initialState: any = {
  data: [],
  status: STATUSES.IDLE,
};
const Voucherslice = createSlice({
  name: "vouchers",
  initialState,
  reducers: {
    setFromPartyVouchers(state, action) {
      state.fromParty = action.payload;
    },
    setToPartyVouchers(state, action) {
      state.toParty = action.payload;
    },
  },
});

export const { setFromPartyVouchers, setToPartyVouchers } =
  Voucherslice.actions;
export default Voucherslice.reducer;
