import { AxiosRequestConfig } from "axios";
import axiosServiceInstance from "../AxiosInstanceService";
import { IBill, IQuotation } from "../../Common/Interfaces/ITransaction";
import { baseUrl, configHeaders } from "../../Common/Config/Config";

export const getQuotationsService = () => {
  let config: AxiosRequestConfig = configHeaders;
  return axiosServiceInstance.get<IBill[]>(
    `${baseUrl}/api/transaction/quotations`,
    config
  );
};

export const getQuotationService = (quotationId: string) => {
  let config: AxiosRequestConfig = configHeaders;
  return axiosServiceInstance.get<IBill[]>(
    `${baseUrl}/api/transaction/quotation/${quotationId}`,
    config
  );
};

export const saveQuotationsService = (payload: IQuotation) => {
  let config: AxiosRequestConfig = configHeaders;
  return axiosServiceInstance.post(
    `${baseUrl}/api/transaction/quotation`,
    payload,
    config
  );
};

export const deleteQuotationService = (quoteId?: string) => {
  let config: AxiosRequestConfig = configHeaders;
  let id = { Id: quoteId };
  return axiosServiceInstance.post(
    `${baseUrl}/api/transaction/removequotation`,
    id,
    config
  );
};

export const getQuotationsbyCustService = (custId: string | undefined) => {
  let config: AxiosRequestConfig = configHeaders;
  return axiosServiceInstance.get<IBill[]>(
    `${baseUrl}/api/transaction/quotationbycust/${custId}`,
    config
  );
};
