import { AxiosRequestConfig } from "axios";
import { baseUrl, configHeaders } from "../../Common/Config/Config";
import axiosServiceInstance from "../AxiosInstanceService";
import { IDeliveryChallan } from "../../Common/Interfaces/ITransaction";

export const getDeliveryChallansService = () => {
  let config: AxiosRequestConfig = configHeaders;
  return axiosServiceInstance.get<IDeliveryChallan[]>(
    `${baseUrl}/api/transaction/challans`,
    config
  );
};
export const getDeliveryChallanService = (dcId: string) => {
  let config: AxiosRequestConfig = configHeaders;
  return axiosServiceInstance.get<IDeliveryChallan[]>(
    `${baseUrl}/api/transaction/challan/${dcId}`,
    config
  );
};
export const saveDeliveryChallansService = (payload: IDeliveryChallan) => {
  let config: AxiosRequestConfig = configHeaders;
  return axiosServiceInstance.post(
    `${baseUrl}/api/transaction/challan`,
    payload,
    config
  );
};

export const deleteDeliveryChallansService = (dcId: string) => {
  let config: AxiosRequestConfig = configHeaders;
  let obj = { Id: dcId };
  return axiosServiceInstance.post(
    ` ${baseUrl}/api/transaction/deliverychallan/remove`,
    obj,
    config
  );
};
