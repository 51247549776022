import { AxiosRequestConfig } from "axios";
import { IParty, pageInfo } from "../../Common/Interfaces";
import axiosServiceInstance from "../AxiosInstanceService";
import { baseUrl, configHeaders } from "../../Common/Config/Config";

export const getPartiesService = () => {
  let config: AxiosRequestConfig = configHeaders;
  return axiosServiceInstance.get<IParty[]>(
    `${baseUrl}/api/masters/parties`,
    config
  );
};

export const savePartyService = (payload: IParty) => {
  let config: AxiosRequestConfig = configHeaders;
  return axiosServiceInstance.post(
    `${baseUrl}/api/masters/party`,
    payload,
    config
  );
};

export const deletePartyService = (payload: IParty) => {
  let config: AxiosRequestConfig = configHeaders;
  const obj = { Id: payload };
  return axiosServiceInstance.post(
    `${baseUrl}/api/masters/removeparty`,
    obj,
    config
  );
};

export const paginationPartyService = (payload: pageInfo) => {
  let config: AxiosRequestConfig = configHeaders;
  return axiosServiceInstance.post(
    `${baseUrl}/api/masters/pageparties`,
    payload,
    config
  );
};
