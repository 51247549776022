import { IBill, IQuotation } from "../../Common/Interfaces/ITransaction";
import {
  notifyError,
  notifyTopRight,
} from "../../jsx/components/PluginsMenu/Toastr/Toastr";
import _ from "lodash";
import {
  deleteQuotationService,
  getQuotationService,
  getQuotationsService,
  getQuotationsbyCustService,
  saveQuotationsService,
} from "../../services/TransactionServices/QuotationsService";
import {
  addQuotations,
  setQuotationPrint,
  setQuotations,
} from "../../store/Slices/Transactions/QuotationsSlice";
import { CRUD_MESSAGES } from "../../Common/Enums/SharedEnums";
import {
  setQuoteObj,
  setSalePrint,
} from "../../store/Slices/Transactions/SalesSlice";

export const fetchQuotationsThunk = () => {
  return async function (dispatch: any, getstate: any) {
    try {
      const result = await getQuotationsService();
      dispatch(addQuotations(result.data));
    } catch (err) {
      console.log("err", err);
    }
  };
};
export const fetchQuotationThunk = (saleId: string) => {
  return async function (dispatch: any) {
    try {
      const result = await getQuotationService(saleId);
      dispatch(setQuotationPrint(result.data));
    } catch (err) {
      console.log("err", err);
    }
  };
};
export const fetchQuotationForSalesThunk = (quoteId: string) => {
  return async function (dispatch: any) {
    try {
      const result = await getQuotationService(quoteId);
      // dispatch(setQuoteObj(result.data));
      dispatch(setSalePrint(result.data));
    } catch (err) {
      console.log("err", err);
    }
  };
};
export const saveQuotationsThunk = (payload: IQuotation) => {
  return async function (dispatch: any, getstate: any) {
    try {
      console.log("payload ", payload);
      const result = await saveQuotationsService(payload);

      if (result.data.status === 200) {
        dispatch(fetchQuotationsThunk());
        notifyTopRight(
          _.isEmpty(payload.SeriesId)
            ? CRUD_MESSAGES.SAVE_MSG
            : CRUD_MESSAGES.EDIT_MSG
        );
      } else {
        console.log(result);
        notifyError(result.data.msg);
      }
    } catch (ex) {
      console.error("Error saveCategory", ex);
    }
  };
};
export const deleteQuotationThunk = (quoteId?: string) => {
  return async function (dispatch: any) {
    try {
      const result = await deleteQuotationService(quoteId);
      dispatch(fetchQuotationsThunk());
    } catch (err) {
      console.log("err", err);
    }
  };
};
export const fetchQuotationsByCustomerThunk = (custId: string | undefined) => {
  return async function (dispatch: any) {
    try {
      const result = await getQuotationsbyCustService(custId);
      dispatch(setQuotations(result.data));
    } catch (err) {
      console.log("err", err);
    }
  };
};
