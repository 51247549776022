import _ from "lodash";
import { CRUD_MESSAGES } from "../../Common/Enums/SharedEnums";
import { IDeliveryChallan } from "../../Common/Interfaces/ITransaction";
import {
  notifyError,
  notifyTopRight,
} from "../../jsx/components/PluginsMenu/Toastr/Toastr";
import {
  deleteDeliveryChallansService,
  getDeliveryChallanService,
  getDeliveryChallansService,
  saveDeliveryChallansService,
} from "../../services/TransactionServices/DeliveryChallanService";
import {
  addDeliveryChallan,
  setDeliveryChallanPrint,
} from "../../store/Slices/Transactions/DeliveryChallanSlice";

export const fetchDeliveryChallansThunk = () => {
  return async function (dispatch: any, getstate: any) {
    try {
      const result = await getDeliveryChallansService();
      dispatch(addDeliveryChallan(result.data));
    } catch (err) {
      console.log("err", err);
    }
  };
};
export const fetchDeliveryChallanThunk = (challanId: string) => {
  return async function (dispatch: any) {
    try {
      const result = await getDeliveryChallanService(challanId);
      dispatch(setDeliveryChallanPrint(result.data));
    } catch (err) {
      console.log("err", err);
    }
  };
};
export const saveDeliveryChallansThunk = (payload: IDeliveryChallan) => {
  return async function (dispatch: any, getstate: any) {
    try {
      console.log("payload ", payload);
      const result = await saveDeliveryChallansService(payload);

      if (result.data.status === 200) {
        dispatch(fetchDeliveryChallansThunk());
        notifyTopRight(
          _.isEmpty(payload.Id)
            ? CRUD_MESSAGES.SAVE_MSG
            : CRUD_MESSAGES.EDIT_MSG
        );
      } else {
        console.log(result);
        notifyError(result.data.msg);
      }
    } catch (ex) {
      console.error("Error saveCategory", ex);
    }
  };
};
export const deleteDeliveryChallanThunk = (challanId: string) => {
  return async function (dispatch: any) {
    try {
      const result = await deleteDeliveryChallansService(challanId);
      dispatch(fetchDeliveryChallansThunk());
    } catch (err) {
      console.log("err", err);
    }
  };
};
