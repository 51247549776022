export const SWEET_DELETE = {
  title: "Are You Sure?",
  text: "You Want to Delete This Record ?",
  buttons: ["Cancel", "Delete"],
};
export const SWEET_EXIT = {
  title: "Are You Sure",
  text: "You want exit without saving changes",
  buttons: ["Exit", "Save"],
};
