import { createSlice } from "@reduxjs/toolkit";
import { IBill } from "../../../Common/Interfaces/ITransaction";

enum STATUSES {
  IDLE = "idle",
  ERROR = "error",
  LOADING = "loading",
}
const initialState: any = {
  data: [],
  status: STATUSES.IDLE,
};
const SalesSlice = createSlice({
  name: "sales",
  initialState,
  reducers: {
    addSales(state, action) {
      state.data = action.payload.data;
    },
    removeSales(state, action) {
      return state.filter((item: any) => item.id !== action.payload);
    },
    setSalePrint(state, action) {
      state.sale = action.payload.data;
    },
    setQuoteObj(state, action) {
      state.quote = action.payload.data;
    },
    setSalesByCustIdObj(state, action) {
      state.salesByCustId = action.payload.data;
    },
  },
});

export const { addSales, removeSales, setSalePrint, setQuoteObj,setSalesByCustIdObj } =
  SalesSlice.actions;
export default SalesSlice.reducer;
