import { IItem, IParty } from "../../../../Common/Interfaces";
import {
  IDeliveryChallan,
  IDeliveryChallanItems,
} from "../../../../Common/Interfaces/ITransaction";

export const defaultParty: IParty = {
  Id: "",
  PartyNo: "",
  PartyName: "",
  PartyType: "",
  Address: "",
  State: "Maharashtra",
  Email: "",
  Mobile: "",
  PAN: "",
  ADHAR: "",
  Desc: "",
  LedgerId: "",
  OpeningBalance: 0.0,
  CreditLimit: 0.0,
  CreditPeriod: 0.0,
  GSTIN: "",
  Status: 1,
  CreatedBy: 1,
  UpdatedBy: 1,
  FixedRate: "",
  Route: "",
};

export const defaultItem: IItem = {
  Id: "",
  UnitId: "",
  UnitName: "",
  ItemName: "",
  Desc: "",
  Barcode: "",
  OpeningQty: 0,
  CategoryId: "",
  CategoryName: "",
  ItemCode: "",
  TaxRate: 0,
  HSNCode: "",
  ManufacturerId: "",
  ManufacturerName: "",
  Packing: "",
  Shelf: "",
  MaxLevel: "",
  RecorderLevel: 0,
  LastPurchaseRate: 0,
  MRP: 0,
  Discount: 0,
  CashRate: 0,
  CreditRate: 0,
  WholesaleRate: 0,
  Rate4: 0,
  Rate5: 0,
  Status: true,
  CreatedBy: 0,
  UpdatedBy: "",
};

export const defaultPage = { Page: 1, PerPage: 10 };

export let DeliveryChallanItem: IDeliveryChallanItems = {
  ItemId: "",
  ItemDescription: "",
  UnitId: "",
  Qty: 0,
};

export let DeliveryChallans: IDeliveryChallan = {
  Id: "",
  Prefix: "",
  SeriesId: "",
  DCSeriesId: "",
  DCNo: 0,
  DCDate: getTodayDate(),
  CustId: "",
  PartyName: "",
  Address: "",
  ByHand: "",
  VehicleDetails: "",
  VehicleContact: 0,
  DispatchedAt: "",
  DispatchedAtContact: "",
  Site: "",
  TotQty: 0,
  Permissionby: "",
  CheckedBy: "",
  IsDelivered: true,
  FirmId: "",
  IsInvoiced: "",
  CreatedBy: "",
  CreatedOn: "",
  UpdatedBy: "",
  UpdatedOn: "",
  DeliveryChallanItems: [DeliveryChallanItem],
};
function getTodayDate() {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}
