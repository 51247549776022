import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import { IBill } from "../../../../Common/Interfaces/ITransaction";
import { fetchSalesThunk } from "../../../../Thunk/TransactionThunk/SalesThunk";
import { useReactToPrint } from "react-to-print";
import { IItemWiseSalesReport } from "../../../../Common/Interfaces/IReport";
import ItemWiseSalesModal from "./ItemWiseSalesModal";
// import SalesReportModal from "./SalesReportModal";

const ItemWiseSalesReport = () => {
  const [itemwisesalesshow, setItemwisesalesshow] = useState(false);
  const handleItemWiseSalesClose = () => setItemwisesalesshow(false);
  const handleItemWiseSalesShow = () => setItemwisesalesshow(true);

  const itemWiseSales = useAppSelector((state) => state.reports.itemWiseSales);

  const openingBalance = useAppSelector(
    (state) => state.reports.openingBalance
  );

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    pageStyle: `@media print {
        @page {
          margin-top: 20px;
        }
      }`,
    documentTitle: "Sales Report",
    content: () => componentRef.current,
  });

  return (
    <>
      <div className="PrintPage">
        <ItemWiseSalesModal
          itemwisesalesshow={itemwisesalesshow}
          handleItemWiseSalesShow={handleItemWiseSalesShow}
          handleItemWiseSalesClose={handleItemWiseSalesClose}
        />

        <div className="d-flex align-items-center">
          {" "}
          <button className=" btn btn-primary mb-4 mr-2" onClick={handlePrint}>
            Print
          </button>{" "}
          <button className=" btn btn-primary mb-4 mr-2">CSV</button>
          <button className="btn btn-success mb-4 mr-2">Excel</button>{" "}
          <button
            className="btn btn-primary mb-4 mr-2"
            onClick={handleItemWiseSalesShow}
          >
            <i className="fa fa-filter"></i> Filter
          </button>{" "}
        </div>

        <div className="card " ref={componentRef}>
          <div className="card-body">
            <h3 className="text-center mb-0">Soulsoft Pvt. Ltd.</h3>
            <p className="text-center mb-2">
              S10 B , AHMEDNAGAR, MAHARASHTRA, 422605
            </p>
            <h4 className="text-center bg-primary text-white p-1">
              Item Wise Sales Report
            </h4>
            <div className="d-flex align-items-center justify-content-center p-2 my-2  bg-light ">
              <h5 className="mb-0 ">
                <strong> Date:</strong> {itemWiseSales?.FromDate} To{" "}
                {itemWiseSales?.ToDate}
              </h5>
            </div>

            <table className=" PrintTable">
              <thead className="bg-primary">
                <th>Item Name</th>
                <th>Unit</th>
                <th>Qty</th>
                <th>Total Amount (₹)</th>
              </thead>
              <tbody>
                {itemWiseSales &&
                  itemWiseSales?.map((sale: IItemWiseSalesReport) => (
                    <tr key={sale.Id}>
                      <td>{sale.Name}</td>
                      <td>{sale.Unit}</td>
                      <td>{sale.TotalQty}</td>
                      <td>{sale.Total}</td>
                    </tr>
                  ))}
                {/* <tr>
                  <td>
                    <strong>Grand Total</strong>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr> */}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default ItemWiseSalesReport;
