import { IItem ,pageInfo} from "../../Common/Interfaces";
import { deleteItemService, getItemsService, saveItemService , paginationItemService} from "../../services/MastersServices/ItemService";
import { setAllItemsSlice, setItemsSlice } from "../../store/Slices/ItemSlice";
import { CRUD_MESSAGES } from "../../Common/Enums/SharedEnums";
import { notifyError, notifyTopRight } from "../../jsx/components/PluginsMenu/Toastr/Toastr";
import _ from 'lodash';
const defaultPage = {Page:1,PerPage:10}

export const  fetchItemsThunk=()=>
{
    return async function(dispatch:any,getstate:any)
    {
            try
            {
                const result = await getItemsService();
                dispatch(setAllItemsSlice(result.data))
            }
            catch(err)
            {
                console.log('error fetchItem',err)
            }
    }
}

export const saveItemThunk=(payload:IItem,pageInfo:pageInfo=defaultPage)=>{
    return async function (dispatch:any, getstate:any)
    {
        try{
                const result = await saveItemService(payload);
                if(result.data.status===200)
                {
                    dispatch(paginationItemThunk(pageInfo))
                    dispatch(fetchItemsThunk())
                    notifyTopRight(_.isEmpty(payload.Id) ? CRUD_MESSAGES.SAVE_MSG : CRUD_MESSAGES.EDIT_MSG )
                }
                else{
                    notifyError(result.data.msg);
                }
                
        }
        catch(ex)
        {
            console.error('Error saveItem',ex)
        }
    }
 }

 export const deleteItemIdThunk = (payload:IItem,pageInfo:pageInfo=defaultPage)=>{
    return async function (dispatch:any,getstate:any)
    {
        try{
            const result = await deleteItemService(payload);
            if(result.data.status===200)
                {
                    dispatch(paginationItemThunk(pageInfo))
                    notifyTopRight(CRUD_MESSAGES.DELETE_MSG)
                }
                else
                {
                    notifyError(result.data.msg);
                }
                
        }
        catch(ex)
        {
            console.error('Error deleting unit', ex);
        }
    }
 }

export const paginationItemThunk = (payload:any) =>
{
    return async function (dispatch:any,getstate:any)
    {
        try
        {
            const result = await paginationItemService(payload)
            dispatch(setItemsSlice(result.data))
        }
        catch(ex)
        {
            console.log('Error pageItemLoad',ex)
        }
    }
}