import { ICategory ,pageInfo} from "../../Common/Interfaces";
import { deleteCategoryService, getCategoryService, paginatioCategoryService, saveCategoryService } from "../../services/MastersServices/CategoryService";
import { setAllCategoriesSlice, setCategoriesSlice } from "../../store/Slices/CategorySlice";
import { CRUD_MESSAGES } from "../../Common/Enums/SharedEnums";
import { notifyError, notifyTopRight } from "../../jsx/components/PluginsMenu/Toastr/Toastr";
import _ from 'lodash';
const defaultPage = {Page:1,PerPage:10}

export const  fetchCategoriesThunk=()=>
{ 
    return async function (dispatch:any,getstate:any)
    {
            try
            {
                const result = await getCategoryService();
                dispatch(setAllCategoriesSlice(result.data))
            }
            catch(err)
            {
                console.log('err',err)
            }
    }
}
 export const saveCategoryThunk=(payload:ICategory,pageInfo:pageInfo=defaultPage)=>{
    return async function (dispatch:any, getstate:any)
    {
        try{
                const result = await saveCategoryService(payload);
                if(result.data.status===200){
                    dispatch(paginationCategoryThunk(pageInfo))
                    notifyTopRight(_.isEmpty(payload.Id) ? CRUD_MESSAGES.SAVE_MSG : CRUD_MESSAGES.EDIT_MSG )
                }
                else{
                    notifyError(result.data.msg);
                }
        }
        catch(ex)
        {
            console.error('Error saveCategory',ex)
        }
    }
 }

 export const deleteCategoryThunk = (payload:any,pageInfo:pageInfo=defaultPage) =>
 {
    return async function (dispatch:any,getstate:any)
    {
        try
        {
            const result = await deleteCategoryService(payload)
            if(result.data.status===200){
                dispatch(paginationCategoryThunk(pageInfo)) 
                notifyTopRight(CRUD_MESSAGES.DELETE_MSG)
            }
            else
            {
                notifyError(result.data.msg);
            } 
        }
        catch(ex)
        {
            console.log('Error deleteCategoty',ex)
        }
    }
 }

 export const paginationCategoryThunk = (payload:any) =>
 {
    return async function (dispatch:any,getstate:any)
    {
        try
        {
            const result = await paginatioCategoryService(payload)
            dispatch(setCategoriesSlice(result.data))
        }
        catch(ex)
        {
            console.log('Error pageCategoryLoad',ex)
        }
    }
 }