import { AxiosRequestConfig } from "axios";
import { IItem } from "../../Common/Interfaces";
import axiosServiceInstance from "../AxiosInstanceService";
import { baseUrl, configHeaders } from "../../Common/Config/Config";

export const getItemsService = () => {
  let config: AxiosRequestConfig = configHeaders;
  return axiosServiceInstance.get<IItem[]>(
    `${baseUrl}/api/masters/items`,
    config
  );
};

export const saveItemService = (payload: IItem) => {
  let config: AxiosRequestConfig = configHeaders;
  return axiosServiceInstance.post(
    `${baseUrl}/api/masters/item`,
    payload,
    config
  );
};

export const deleteItemService = (payload: IItem) => {
  let config: AxiosRequestConfig = configHeaders;
  let obj = { Id: payload };
  return axiosServiceInstance.post(
    `${baseUrl}/api/masters/removeitem`,
    obj,
    config
  );
};

export const paginationItemService = (payload: IItem) => {
  let config: AxiosRequestConfig = configHeaders;
  return axiosServiceInstance.post(
    `${baseUrl}/api/masters/pageitems`,
    payload,
    config
  );
};
