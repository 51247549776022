import { Form, Field, Formik, FormikValues } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IParty } from "../../../../Common/Interfaces";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import * as Yup from "yup";
import DeliveryChallanBill from "./DeliveryChallanReturnBill";
import {
  DeliveryChallans,
  defaultItem,
  defaultPage,
  defaultParty,
} from "./DeliveryChallanReturnObjects";
import { fetchItemsThunk } from "../../../../Thunk/MasterThunk/ItemThunk";
import { fetchPartiesThunk } from "../../../../Thunk/MasterThunk/PartyThunk";
import { IDeliveryChallan } from "../../../../Common/Interfaces/ITransaction";
import {
  fetchDeliveryChallanThunk,
  fetchDeliveryChallansThunk,
  saveDeliveryChallansThunk,
} from "../../../../Thunk/TransactionThunk/DeliveryChallanThunk";
import _ from "lodash";
import SweetConfirm from "../../PluginsMenu/SweetAlert/SweetConfirm";
import { SWEET_EXIT } from "../../../../Common/SweetMessages/SweetMessages";
import PartyModal from "../../Masters/Party/PartyModal";
import ItemModal from "../../Masters/Item/ItemModal";
import FormTitle from "../../../layouts/FormTitle";
import DeliverChallanGrid from "./DeliveryChallanReturnGrid";

const DeliveryChallanForm = () => {
  const [customer, setCustomer] = useState<IParty>();
  const [initialValues, setInitialValues] = useState(
    structuredClone(DeliveryChallans)
  );
  const params = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const challan = useAppSelector(
    (state) => state.deliverychallan.deliverychallan
  );
  const parties = useAppSelector((state) => state.party.allData);

  useEffect(() => {
    dispatch(fetchItemsThunk());
    dispatch(fetchPartiesThunk());
  }, []);

  const getChallanObj = (challan: IDeliveryChallan) => {
    return {
      Id: challan.Id,
      Prefix: challan.Prefix,
      SeriesId: challan.SeriesId,
      DCSeriesId: challan.DCSeriesId,
      DCNo: challan.DCNo,
      DCDate: challan.DCDate,
      CustId: challan?.CustId,
      PartyName: challan.PartyName,
      Address: challan.Address,
      ByHand: challan?.ByHand,
      VehicleDetails: challan?.VehicleDetails,
      VehicleContact: challan?.VehicleContact,
      DispatchedAt: challan?.DispatchedAt,
      DispatchedAtContact: challan?.DispatchedAtContact,
      Site: challan?.Site,
      TotQty: challan?.TotQty,
      Permissionby: challan?.Permissionby,
      CheckedBy: challan?.CheckedBy,
      IsDelivered: challan?.IsDelivered,
      FirmId: challan?.FirmId,
      IsInvoiced: challan?.IsInvoiced,
      CreatedBy: challan?.CreatedBy,
      CreatedOn: challan?.CreatedOn,
      UpdatedBy: challan?.UpdatedBy,
      UpdatedOn: challan?.UpdatedOn,
      DeliveryChallanItems: challan?.DeliveryChallanItems,
    };
  };

  useEffect(() => {
    if (challan) {
      let initialFormikBillValues = getChallanObj(challan);
      setInitialValues(structuredClone(initialFormikBillValues));
    }
  }, [challan]);
  useEffect(() => {
    const fetchData = async () => {
      if (params.dcId) {
        if (_.isEmpty(challan)) {
          // SalesFunction(sale);
          await dispatch(fetchDeliveryChallanThunk(params.dcId));
        }
      }
    };
    fetchData();
  }, [params.dcId]);

  // const handleQuoteObj = () => {
  //   setIsQuotation(true);
  // };

  function handleSave(arg0: IDeliveryChallan): void {
    dispatch(saveDeliveryChallansThunk(arg0));
  }

  const handleCustomerChange = (e: any, formik: FormikValues) => {
    const selectedParty = parties?.find(
      (item: IParty) => item.Id === e.target.value
    );
    setCustomer(selectedParty);
    formik.values.PartyName = selectedParty?.PartyName;
    formik.values.Address = selectedParty?.Address;
  };

  function handleExit(formik: FormikValues, isSalesFormEdited: boolean): void {
    if (isSalesFormEdited) {
      const handleSweetDelete = async () => {
        const result = await SweetConfirm(
          SWEET_EXIT.title,
          SWEET_EXIT.text,
          SWEET_EXIT.buttons
        );
        if (result) {
          handleSave(formik.values);
        } else {
          navigate("/deliverychallanreturn");
        }
      };
      handleSweetDelete();
    } else {
      navigate("/deliverychallanreturn");
    }
  }
  const validationSchema = Yup.object().shape({
    BillItems: Yup.array().of(
      Yup.object().shape({
        Qty: Yup.number().min(1, "required").required("qty is required"),
      })
    ),
  });
  return (
    <div className="">
      <PartyModal party={defaultParty} pageInfo={defaultPage} />
      <ItemModal item={defaultItem} pageInfo={defaultPage} />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={(values, onSubmitForm) => {
          handleSave(values);
          onSubmitForm.resetForm();
        }}
      >
        {(formik) => (
          <Form className="">
            <div className="row m-1 TransactionHeader">
              <div className="col-md-3 p-1">
                <div className="TransactionHeaderCard">
                  {" "}
                  <FormTitle pageHeading="Delivery Challan Return" />
                  <div className="d-flex align-items-center">
                    <p className="CustomizedPara text-nowrap mr-2">
                      Customer Name:
                    </p>

                    <Field
                      autoFocus
                      as="select"
                      className="SalesFormNewControl w-100 "
                      id="CustId"
                      name="CustId"
                      onChange={(e: any) => {
                        formik.handleChange(`CustId`)(e);
                        handleCustomerChange(e, formik);
                      }}
                      // onClick={(e: any) => handleCustomerChange(e, formik)}
                    >
                      <option value="">Select Customer</option>
                      {parties?.map(
                        (customer: IParty) =>
                          customer.PartyType === "customer" && (
                            <option key={customer.Id} value={customer.Id}>
                              {customer.PartyName}
                            </option>
                          )
                      )}
                    </Field>
                    {/* <i
                      className="fa fa-plus cursor-pointer text-primary"
                      data-toggle="modal"
                      data-target="#partyModal"
                    >
                      {" "}
                    </i> */}
                  </div>
                  <div className="d-flex align-items-center">
                    <p className="CustomizedPara text-nowrap mr-2">Address: </p>
                    <p className="CustomizedPara text-primary ">
                      {customer?.Address}
                    </p>
                  </div>
                  <div className="d-flex align-items-center">
                    <p className="CustomizedPara mr-2">Mobile: </p>
                    <p
                      className="CustomizedPara w-100"
                      style={{
                        fontWeight: "500",
                        color: "green",
                      }}
                    >
                      +91 {customer?.Mobile}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 p-1">
                <div className="TransactionHeaderCard">
                  <div className="d-flex align-items-center ">
                    <p className="CustomizedPara mr-2">
                      Outstanding (&#8377;):
                    </p>
                    <p className="CustomizedPara text-success">
                      {customer?.OpeningBalance
                        ? customer.OpeningBalance + "/-"
                        : "--"}
                    </p>
                  </div>
                  <div className="d-flex align-items-center">
                    <p className="CustomizedPara mr-2">State:</p>
                    <p className="CustomizedPara text-primary">
                      {" "}
                      {customer?.State ? customer.State : "--"}
                    </p>
                  </div>
                </div>

                {/* <div className="d-flex align-items-center">
                  <p className="CustomizedPara mr-2">By hand:</p>
                  <Field
                      type="text"
                      className=" SalesFormNewControl"
                      name="ByHand"
                      id=""
                    />
                </div>
                <div className="d-flex align-items-center">
                  <p className="CustomizedPara mr-2">By vehicle:</p>
                  <Field
                      type="text"
                      className=" SalesFormNewControl"
                      name="ByVehicle"
                      id=""
                    />
                </div> */}
              </div>
              <div className="col-md-3 p-1">
                {" "}
                <div className="TransactionHeaderCard">
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="CustomizedPara mr-2">Challan No:</p>
                    <Field
                      type="text"
                      className=" SalesFormNewControl"
                      name="DCNo"
                      id=""
                    />
                    {/* <Field type="text" /> */}
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="CustomizedPara mr-2">Challan Date:</p>
                    <Field
                      type="date"
                      className=" SalesFormNewControl"
                      name="DCDate"
                      id=""
                    />
                    {/* <Field type="text" /> */}
                  </div>
                </div>
              </div>
              <div className="col-md-3 p-1">
                <div className="TransactionHeaderCard bg-white d-flex flex-column  ">
                  <p className="CustomizedPara badge badge-light text-primary text-left mb-1">
                    {" "}
                    <span
                      className="text-primary cursor-pointer"
                      onClick={() => handleExit(formik, formik.dirty)}
                    >
                      DC
                    </span>{" "}
                    /{" "}
                    <span className="text-warning cursor-pointer">DC Form</span>
                  </p>

                  <p
                    className="CustomizedPara badge badge-light text-primary text-left mb-1 cursor-pointer"
                    data-toggle="modal"
                    data-target="#partyModal"
                  >
                    <i className="fa fa-plus mr-2"></i> Add New Customer
                  </p>
                  <p
                    className="CustomizedPara badge badge-light text-primary text-left mb-1 cursor-pointer"
                    data-toggle="modal"
                    data-target="#itemModal"
                  >
                    <i className="fa fa-plus mr-2"></i> Add New Item
                  </p>
                </div>
              </div>
            </div>
            <div className="row m-1 DeliveryChallanCard">
              <div className="col-md-8 p-0">
                {" "}
                <div className="card m-1 p-2">
                  {formik.values.DeliveryChallanItems && (
                    <DeliveryChallanBill />
                  )}
                </div>
              </div>
              <div className="col-md-4 p-0 ">
                <div className="card m-1 p-2 DeliveryChallanGrid">
                  <DeliverChallanGrid />
                </div>
              </div>
            </div>

            <div className="row SalesFooter ml-0 mr-0 ">
              <div className="col-md-8  ">
                <div className=" row align-items-center">
                  <div className="col-md-2 p-0 pr-1">
                    <p className="CustomizedPara">Total Quantity </p>
                    <Field
                      type="text"
                      name="TotQty"
                      id=""
                      className="SalesFormNewControl w-100"
                    />
                  </div>{" "}
                  <div className="col-md-2 p-0 pr-1"></div>
                </div>
              </div>
              <div className="col-md-4 ">
                <div
                  className=" row align-items-center justify-content-end mb-2"
                  style={{ gap: "3px" }}
                ></div>
                <div
                  className=" row align-items-center justify-content-end "
                  style={{ gap: "3px" }}
                >
                  <div className="col-md-3 p-0  ">
                    {" "}
                    <button type="submit" className="button-31 ">
                      Save bill
                    </button>
                  </div>
                  <div className="col-md-3 p-0 ">
                    {" "}
                    <button
                      type="button"
                      className="button-31 "
                      onClick={() => handleExit(formik, formik.dirty)}
                    >
                      Exit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default DeliveryChallanForm;
