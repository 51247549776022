import { createSlice } from "@reduxjs/toolkit";

enum STATUSES {
  IDLE = "idle",
  ERROR = "error",
  LOADING = "loading",
}
const initialState: any = {
  status: STATUSES.IDLE,
};
const DashboardSlice = createSlice({
  name: "dashboard",
  initialState: initialState,
  reducers: {
    setTotalSales(state, action) {
      state.totalSales = action.payload;
    },
  },
});

export const { setTotalSales } = DashboardSlice.actions;
export default DashboardSlice.reducer;
