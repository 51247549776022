import { IParty, pageInfo } from "../../Common/Interfaces";
import { deletePartyService, getPartiesService, paginationPartyService, savePartyService } from "../../services/MastersServices/PartyService";
import { setAllPartySlice, setPartySlice } from "../../store/Slices/PartySlice";
import { CRUD_MESSAGES } from "../../Common/Enums/SharedEnums";
import { notifyError, notifyTopRight } from "../../jsx/components/PluginsMenu/Toastr/Toastr";
import _ from 'lodash';

const defaultPage = {Page:1,PerPage:10}

export const  fetchPartiesThunk=()=>
{
    return async function (dispatch:any,getstate:any)
    {
            try
            {
                const result = await getPartiesService();
                dispatch(setAllPartySlice(result.data))
            }
            catch(err)
            {
                console.log('err',err)
            }
    }
} 

export const savePartyThunk=(payload:IParty,pageInfo:pageInfo=defaultPage)=>{
    return async function (dispatch:any, getstate:any)
    {
        try{
                const result = await savePartyService(payload); 
                if(result.data.status===200)
                {
                    dispatch(paginationPartyThunk(pageInfo))
                    dispatch(fetchPartiesThunk())  
                    notifyTopRight(_.isEmpty(payload.Id) ? CRUD_MESSAGES.SAVE_MSG : CRUD_MESSAGES.EDIT_MSG )
                }
                else{
                    notifyError(result.data.msg);
                }    
        }
        catch(ex)
        {
            console.error('Error saveParty',ex)
        }
    }
 }

 export const deletePartyThunk = (payload:IParty,pageInfo:pageInfo=defaultPage) =>
 {
    return async function (dispatch:any, getstate:any)
    {
        try
        {
            const result = await deletePartyService(payload);
            if(result.data.status===200)
                {
                    dispatch(paginationPartyThunk(pageInfo))  
                    notifyTopRight(CRUD_MESSAGES.DELETE_MSG)
                }
                else
                {
                    notifyError(result.data.msg);
                } 
        }
        catch(ex)
        {
            console.log('Error deleteParty',ex);
        }
    }
 }

 export const paginationPartyThunk = (payload:pageInfo) =>
 {
    return async function (dispatch:any,getstate:any)
    {
        try
        {
            const result = await paginationPartyService(payload)
            dispatch(setPartySlice(result.data))
        }
        catch(ex)
        {
            console.log('Error pagePartyLoad',ex)
        }
    }
 }