import { createSlice } from "@reduxjs/toolkit";

enum STATUSES {
  IDLE = "idle",
  ERROR = "error",
  LOADING = "loading",
}
const initialState: any = {
  data: [],
  status: STATUSES.IDLE,
};
const DeliveryChallanSlice = createSlice({
  name: "purchasechallan",
  initialState,
  reducers: {
    addPurchaseChallan(state, action) {
      state.data = action.payload.data;
    },
    removePurchaseChallan(state, action) {
      return state.filter((item: any) => item.id !== action.payload);
    },
    setPurchaseChallanPrint(state, action) {
      state.purchasechallan = action.payload.data;
    },
  },
});

export const {
  addPurchaseChallan,
  removePurchaseChallan,
  setPurchaseChallanPrint,
} = DeliveryChallanSlice.actions;
export default DeliveryChallanSlice.reducer;
