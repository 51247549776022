import { CRUD_MESSAGES, VOUCHER_MESSAGE } from "../../Common/Enums/SharedEnums";
import { IPaymentVoucher } from "../../Common/Interfaces/IVoucher";
import { notifyTopRight } from "../../jsx/components/PluginsMenu/Toastr/Toastr";
import { getFromPartyVoucher, getToPartyVoucher, savePaymentVoucher } from "../../services/VouchersServices/PaymentVoucherService";
import { setFromPartyVouchers, setToPartyVouchers } from "../../store/Slices/Vouchers/VouchersSlice";

export const savePaymentVoucherThunk = (payload: IPaymentVoucher) => {
    return async function (dispatch: any, getstate: any) {
      try {
        payload.VchTypeId="4"
        console.log("payment voucher ",payload)
        const result = await savePaymentVoucher(payload);
  
        if (result.data.status === 200) {
          notifyTopRight(
            VOUCHER_MESSAGE. VOUCHER_CREATED
       ) }else {
          console.log(result);
        //   notifyError(result.data.msg);
        }
      } catch (ex) {
        console.error("Error saveCategory", ex);
      }
    };
  };
  export const  getFromPartyVoucherThunk=()=>
    {
        return async function(dispatch:any,getstate:any)
        {
                try
                {
                    const result = await getFromPartyVoucher();
                    dispatch(setFromPartyVouchers(result.data))
                }
                catch(err)
                {
                    console.log('error fetchItem',err)
                }
        }
    }
    export const  getToPartyVoucherThunk=()=>
        {
            return async function(dispatch:any,getstate:any)
            {
                    try
                    {
                        const result = await getToPartyVoucher();
                        dispatch(setToPartyVouchers(result.data))
                    }
                    catch(err)
                    {
                        console.log('error fetchItem',err)
                    }
            }
        }
  