const userDetailsString = localStorage.getItem("userDetails");
const userDetails = userDetailsString ? JSON.parse(userDetailsString) : {};
const token = userDetails.idToken;

export const configHeaders = {
  headers: {
    "content-type": "multipart/form-data",
    Authorization: token,
  },
};

// export const baseUrl="http://localhost:8080"
export const baseUrl = "https://api.soulsofterp.com";
