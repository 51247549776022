import { Table } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import {
  deleteDeliveryChallanThunk,
  fetchDeliveryChallanThunk,
  fetchDeliveryChallansThunk,
} from "../../../../Thunk/TransactionThunk/DeliveryChallanThunk";
import SweetConfirm from "../../PluginsMenu/SweetAlert/SweetConfirm";
import { SWEET_DELETE } from "../../../../Common/SweetMessages/SweetMessages";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import { IDeliveryChallan } from "../../../../Common/Interfaces/ITransaction";
import PageTitle from "../../../layouts/PageTitle";
import { DeliveryChallans } from "./DeliveryChallanReturnObjects";

const DeliveryChallanReturn = () => {
  const deliveryChallans = useAppSelector(
    (state: any) => state.deliverychallan.data
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  function handlePrint(arg0: any): void {
    dispatch(fetchDeliveryChallanThunk(arg0));
    navigate("/salesinvoice");
  }

  // async function handleReturn(arg0: any): Promise<void> {
  //   await dispatch((`/sale/${arg0}`));
  //   navigate(`/salesreturnform/${arg0}`);
  // }

  async function handleEdit(arg0: any): Promise<void> {
    await dispatch(fetchDeliveryChallanThunk(arg0));
    navigate(`/dcreturnform/${arg0}`);
  }

  function handleDelete(arg0: any): void {
    const handleSweetDelete = async () => {
      const result = await SweetConfirm(
        SWEET_DELETE.title,
        SWEET_DELETE.text,
        SWEET_DELETE.buttons
      );
      if (result) {
        dispatch(deleteDeliveryChallanThunk(arg0));
      }
    };
    handleSweetDelete();

    // navigate(`/salesform/${arg0}`);
  }

  async function handleNew(arg0: any): Promise<void> {
    navigate("/dcreturnform");
  }

  useEffect(() => {
    if (deliveryChallans) {
      dispatch(fetchDeliveryChallansThunk());
    }
  }, []);
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">DC Return</h4>
              <div>
                {" "}
                <button
                  type="button"
                  className="btn btn-primary mb-2 mr-2"
                  onClick={() => handleNew(DeliveryChallans)}
                >
                  <i className="fa fa-plus" aria-hidden="true"></i> New Challan
                </button>
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <div id="example-wrapper" className="dataTables_wrapper">
                  <Table id="challanTable" className="display dataTable">
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th>Customer Name</th>
                        <th>Total Quantity </th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {deliveryChallans?.map(
                        (purchaseItem: IDeliveryChallan, index: number) => (
                          <tr>
                            <td>{index}</td>
                            <td>{purchaseItem.PartyName}</td>
                            <td>{purchaseItem.TotQty}</td>
                            <td className="TableIconsContainer">
                              <i
                                className="fa fa-edit TableIcons "
                                data-toggle="modal"
                                data-target="#purchaseModal"
                                onClick={() => handleEdit(purchaseItem.Id)}
                              ></i>
                              <i className="fa fa-trash text-warning TableIcons"></i>
                            </td>
                            <td>
                              <button
                                className="btn btn-primary"
                                onClick={() => {
                                  handlePrint(purchaseItem.Id);
                                }}
                              >
                                Print
                              </button>
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeliveryChallanReturn;
