import { AxiosRequestConfig } from "axios";
import { IUnit } from "../../Common/Interfaces";
import axiosServiceInstance from "../AxiosInstanceService";
import { baseUrl, configHeaders } from "../../Common/Config/Config";

export const getUnitService = () => {
  let config: AxiosRequestConfig = configHeaders;
  return axiosServiceInstance.get<IUnit[]>(
    `${baseUrl}/api/masters/units`,
    config
  );
};

export const saveUnitService = (payload: IUnit) => {
  let config: AxiosRequestConfig = configHeaders;
  return axiosServiceInstance.post(
    `${baseUrl}/api/masters/unit`,
    payload,
    config
  );
};

export const deleteUnitService = (payload: IUnit) => {
  let config: AxiosRequestConfig = configHeaders;
  let obj = { Id: payload };
  return axiosServiceInstance.post(
    `${baseUrl}/api/masters/removeunit`,
    obj,
    config
  );
};

export const paginationUnitService = (payload: IUnit) => {
  let config: AxiosRequestConfig = configHeaders;
  return axiosServiceInstance.post(
    `${baseUrl}/api/masters/pageunits`,
    payload,
    config
  );
};
