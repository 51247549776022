import { ToWords } from "to-words";
import { usePDF } from "react-to-pdf";
import logo from "../../../../images/logo.png";
import { useAppSelector } from "../../../../store/store";
import { IBillItems } from "../../../../Common/Interfaces/ITransaction";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import "./DeliveryChallanInvoice.css";

const DeliveryChallanInvoice = () => {
  const challan = useAppSelector(
    (state) => state.deliverychallan.deliverychallan
  );

  const toWords = new ToWords();

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    pageStyle: `@media print {
        @page {
          margin-top: 20px;
        }
      }`,
    documentTitle: "Delivery Challan",
    content: () => componentRef.current,
  });

  return (
    <>
      {challan && (
        <div className="PrintPage">
          <button className="btn btn-primary mb-4" onClick={handlePrint}>
            Print
          </button>{" "}
          <div ref={componentRef} className="card ">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center">
                <div className="">
                  <img src={logo} alt="" className="PrintLogo" />
                </div>
                <div className="w-50">
                  <h2>Soulsoft Infotech Pvt. Ltd. </h2>{" "}
                  <p>
                    S-10B, 2nd Floor, Above Reliance Digital, Topten Imperial,
                    Sangamner, Dist- Ahmednagar. <br /> <strong>GSTIN:</strong>
                  </p>
                </div>
              </div>
              <h4 className="text-center bg-primary text-white p-1">
                DC Invoice
              </h4>
              <div className="d-flex justify-content-between bg-light p-2 my-2">
                <div className="">
                  <h5>
                    <strong>Bill to:</strong>
                  </h5>
                  <h5>{challan?.PartyName}</h5>
                  <h5>{challan?.Address}</h5>
                  <h5>
                    <strong>GSTIN:</strong>
                  </h5>
                </div>
                <div className="">
                  <h5 className="text-primary">
                    {" "}
                    <strong>Place of Supply</strong>: 27-Maharashtra
                  </h5>
                  <h5 className="text-primary">
                    Invoice No.: {challan?.BillNo}{" "}
                  </h5>
                  <h5 className="text-primary"> Date: {challan?.BillDate}</h5>
                </div>
              </div>
              <div className="">
                <table className="PrintTable">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Item Name</th>
                      <th>Qty</th>
                    </tr>
                  </thead>
                  <tbody>
                    {challan.DeliveryChallanItems?.map(
                      (item: IBillItems, index: number) => (
                        <tr>
                          <td scope="row">{index + 1}</td>
                          <td>{item.ItemName}</td>
                          <td>{item.Qty}</td>
                        </tr>
                      )
                    )}

                    <tr>
                      <td></td>
                      <td>
                        {" "}
                        <strong>Total</strong>
                      </td>
                      <td>
                        <strong>{challan.TotQty}</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* <div className="row my-4">
                <div className="col-8 pr-2">
                  <table className="InvoiceTable mb-4">
                    <thead>
                      <tr>
                        <th>HSN</th>
                        <th>Tax %</th>
                        <th>Taxable</th>
                        <th>CGST</th>
                        <th>SGST</th>
                        <th>IGST</th>
                        <th>Total tax</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>SGST</td>
                        <td>₹ {challan.TotTaxable}</td>
                        <td>
                          {parseFloat(
                            (
                              (challan.TotalTax * 100) /
                              challan.TotTaxable
                            ).toFixed(2)
                          )}
                          %
                        </td>
                        <td>{challan.TotTax / 2}</td>
                        <td>{challan.TotTax / 2}</td>
                        <td>{challan.TotTax / 2}</td>
                        <td>{challan.TotalTax / 2}</td>
                      </tr>
                      <tr>
                        <td>SGST</td>
                        <td>₹ {challan.TotTaxable}</td>
                        <td>
                          {parseFloat(
                            (
                              (challan.TotTax * 100) /
                              challan.TotTaxable
                            ).toFixed(2)
                          )}
                          %
                        </td>
                        <td>{challan.TotTax / 2}</td>
                        <td>{challan.TotTax / 2}</td>
                        <td>{challan.TotTax / 2}</td>
                        <td>{challan.TotTax / 2}</td>
                      </tr>
                      <tr>
                        <td>SGST</td>
                        <td>₹ {challan.TotTaxable}</td>
                        <td>
                          {parseFloat(
                            (
                              (challan.TotTax * 100) /
                              challan.TotTaxable
                            ).toFixed(2)
                          )}
                          %
                        </td>
                        <td>{challan.TotTax / 2}</td>
                        <td>{challan.TotTax / 2}</td>
                        <td>{challan.TotTax / 2}</td>
                        <td>{challan.TotTax / 2}</td>
                      </tr>
                    </tbody>
                  </table>

                  <div>
                    <h5 className="">Invoice Amount in Words</h5>
                    <p>
                      {challan.NetBillAmt &&
                        toWords.convert(challan.NetBillAmt) +
                          " Rupees only"}{" "}
                    </p>
                  </div>
                  <div>
                    <h5 className="">Terms and conditions</h5>
                    <ul>
                      <li></li>
                    </ul>
                  </div>
                </div>
                <div className="col-4 pl-2">
                  <table className="InvoiceTable">
                    <thead>
                      <tr>
                        <th>Amounts:</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="d-flex justify-content-between">
                            <h5>Subtotal</h5>
                            <h5>₹ {challan.NetTotal}</h5>
                          </div>
                        </td>
                      </tr>
                      {challan.ExtraBillCharges != 0 && (
                        <tr>
                          <td>
                            <div className="d-flex justify-content-between">
                              <h5>Extra Bill Charges</h5>
                              <h5>₹ {challan.ExtraBillCharges}</h5>
                            </div>
                          </td>
                        </tr>
                      )}
                      {challan.ExtraBillDiscount != 0 && (
                        <tr>
                          <td>
                            <div className="d-flex justify-content-between">
                              <h5>Bill Discount</h5>
                              <h5>₹ {challan.ExtraBillDiscount}</h5>
                            </div>
                          </td>
                        </tr>
                      )}
                      {challan.RoundOff != 0 && (
                        <tr>
                          <td>
                            <div className="d-flex justify-content-between">
                              <h5>Round Off</h5>
                              <h5>₹ {challan.RoundOff}</h5>
                            </div>
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td>
                          <div className="d-flex justify-content-between py-0">
                            {" "}
                            <h5>
                              <strong>Total</strong>{" "}
                            </h5>
                            <h5>
                              {" "}
                              <strong>₹ {challan.NetBillAmt}</strong>{" "}
                            </h5>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex justify-content-between">
                            {" "}
                            <h5>Received </h5>
                            <h5>₹ {challan.ReceiptAmt}</h5>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="d-flex justify-content-between">
                            {" "}
                            <h5>Balance </h5>
                            <h5>₹ {challan.BalanceBillAmt}</h5>
                          </div>{" "}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DeliveryChallanInvoice;
